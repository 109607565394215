#introRea{
	height: 100vh;
	min-height: 721px;
	#introInt{
		background-repeat: no-repeat;
		background-position: left bottom;
		background-size: cover;
		position: absolute;
		width: 100%;
		height: calc(100vh - 8.375vh);
		min-height: 600px;

		#contenu{
			position: absolute;
			top: 50%;
			right: 24.318429661941114vh;
			width: 100%;
			max-width: 55.28898582333697vh;
			transform: translate(0, -50%);
			background-color: rgba(60,60,59,.71); 
			padding:20px 40px;

			i{
				position: absolute;
				color: $red;
				left: 0;
				font-size: 3.271537622682661vh;
				width: 2.6172300981461287vh;
				padding: 0 10px;
			}
			h2{
				color: $red;
				font-size: 3.271537622682661vh;
				font-family: $mont;
				font-weight: 700;
				line-height: 100%;
				text-transform: uppercase;
				float: left;
				width: 100%;
				max-width: 55.28898582333697vh;
				margin: 0 auto;
				padding: 0 0 0 3.816793893129771vh;
				&:after{
					background: $red;
					display: block;
					content: "";
					width: 5.561613958560524vh;
					height: 0.4362050163576881vh;
					margin: 3.271537622682661vh 0 2.1810250817884405vh -3.2175vw;
				}
			}
			p{
				color: #FFF;
				font-size: 1.5267175572519085vh;
				font-family: $robo;
				font-weight: 400;
				line-height: 2.6172300981461287vh;
				padding: 0;
				max-width: 55.28898582333697vh;
				clear: both;
			}
			.buttons{
				display: flex;
				margin: 2.1810250817884405vh 0 0;
				a{
					position: relative;
					color: $red;
					font-size: 1.3086150490730644vh;
					font-family: $mont;
					font-weight: 700;
					letter-spacing: 0.2em;
					line-height: 100%;
					text-transform: uppercase;
					border: 0.3271537622682661vh solid $red;
					padding: 1.6357688113413305vh 2.0905125408942202vh;
					display: table;
					transition-property: color;
					transition-duration: 0.4s;
					transition-delay: 0.4s;
					& + a{
						margin: 0 0 0 2.1810250817884405vh;
					}
					span{
						position: relative;
						z-index:2;
					}

					&:after{
						background: $red;
						position: absolute;
						display: block;
						content: "";
						width: 0;
						height: 0.25vw;
						left: 50%;
						bottom: 0;
						opacity: 0;
						transform: translate(-50%, 0);
						transition-property: width, height, opacity;
						transition-duration: 0.4s, 0.4s, 0.4s;
						transition-delay: 0s, 0.4s, 0s;
						z-index: 0;
					}
					&:hover{
						color: #fff;
						&:after{
							width: 101%;
							height: 101%;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}


#realisations{
	padding: 8.1875vw 0 7.375vw 0;
	#wrap{
		max-width: 71.6875vw;
		margin: 0 auto;

		h1{
			color: $red;
			font-family: $mont;
			font-size: 1.875vw;
			font-weight: 300;
			text-transform: uppercase;
			margin: 0 0 3.125vw;
		}
		p{
			font-size: 0.875vw;
			font-family: $robo;
			font-weight: 400;
			line-height: 1.5vw;
			strong{
				font-size: 1.25vw;
				line-height: 1.5vw;
			}
			& + p{
				margin: 1.875vw 0 0;
			}
		}
		#projets{
			p{
				color: $red;
				font-size: 0.875vw;
				font-family: $robo;
				font-weight: 300;
				line-height: 1.5vw;
			}
			&:before{
				background: $red;
				display: none;
				content: "";
				width: 2.8125vw;
				height: 1px;
				margin: 2.0625vw 0 2.5vw -1.6875vw;
			}
			#cat{
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				align-content: center;
				margin: 50px auto 70px;
				max-width: 31.25vw;
				zoom: 1;
				&:before, &:after {
					content: "";
					display: table;
				}
				&:after {
					clear: both;
				}
				.service{
					width: 33.3333%;
					cursor: pointer;
					position: relative;
					height: 80px;
					appearance: none;
					background: none;
					border: 0;
					margin-bottom: 10px;
					float: left;
					span{
						display: block;
						opacity: 0;
						color: $red;
						text-align: center;
						transition-property: opacity;
						transition-duration: 0.4s;
						font-size: 0.5625vw;
						font-family: $robo;
						font-weight: 900;
						text-transform: uppercase;
						letter-spacing: 0.2em;
						line-height: initial;
					}
					img{
						display: table;
						margin: 0.625vw auto 0;
						width: auto;
						height: 2.1875vw;
					}
					&.first{
						position: relative;
						display: none;
						width: 100%;
						height: initial;
						padding: 0 0 1.875vw 0;
						img{
							display: none;
						}
						span{
							opacity: 1;
						}
						&:after{
							background: $red;
							position: absolute;
							display: block;
							content: "";
							width: 3.125vw;
							height: 2px;
							left: 50%;
							bottom: 0.9375vw;
							transform: translate(-50%, 0);
						}
					}
					&.is-checked{
						span{
							opacity: 1;
						}
					}
					&:hover{
						span{
							opacity: 1;
						}
					}
					.hidemy2par4{
						opacity: 0 !important;
					}
					&:nth-child(3n + 1){
						span{
							
						}
						img{
							
						}
					}
					&:nth-child(3n + 3){
						&:after{
							background: #d3d3d3;
							position: absolute;
							display: block;
							content: "";
							top: 50%;
							right: 0;
							width: 1px;
							height: 0.6875vw;
							transform: translate(0, -50%);
						}
						&:before{
							background: #d3d3d3;
							position: absolute;
							display: block;
							content: "";
							top: 50%;
							left: 0;
							width: 1px;
							height: 0.6875vw;
							transform: translate(0, -50%);
						}
					}
					&:nth-child(3n + 3){
						span{
							
						}
						img{
							
						}
					}
				}
			}
			#contenu{

				.rea-by-rea{
					display: block;
				}
			}
		}
	}
}

#cat-grid {
	.element-item {
		width: 25%;
		height: 440px;
	}
	a.int {
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		display: flex;
		align-items: center;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		&:before {
			content: '';
			transition: all 0.25s $easeinoutquad;
			opacity: 0;
			background-color: rgba(226,8,22,.80);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 4;
			width: 100%;
			height: 100%;
		}
		&:hover, &:active, &:focus {
			&:before {
				opacity: 1;
			}
			.intint {
				transform: translateY(0);
				opacity: 1;
			}
		}
	}
	.intint {
		transition: all 0.25s $easeinoutquad;
		transform: translateY(-20px);
		opacity: 0;
		position: relative;
		z-index: 8;
		padding: 15px 30px;
		.contenu {
			color: #FFF;
			h3 {
				text-transform: uppercase;
				font-family: $mont;
				font-weight: 700;
				font-size: 14px;
				line-height: 1.25em;
				margin-bottom: 5px;
			}
			span {
				text-transform: uppercase;
				font-size: 10px;
				font-weight: 700;
				span {
					font-size: 14px;
				}
			}
			> span {
				display: block;
				padding-bottom: 14px;
				position: relative;
				&:before {
					content: '';
					background-color: #FFF;
					width: 22px;
					height: 2px;
					position: absolute;
					bottom: 0;
					left: -10px;
				}
			}
			p {
				font-size: 12px;
				font-family: $robo;
				font-weight: 300;
				margin: 13px 0;
				color: #FFF !important;
			}
			img {
				display: inline-block;
				margin-top: 7px;
			}
		}
	}
}