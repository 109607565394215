footer{
	background: #3c3c3b;
	position: relative;
	z-index: 3;
	#top{
		padding: 3.4375vw 0 3.125vw;
		.corps{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			width: 100%;
			height: 100%;
			#logoTop{
				display: block;
				margin: 0 0 2.1875vw;
				width: 100%;
				clear: both;
				a{
					display: table;
					img{
						display: block;
						width: 223px;
						height: 63px;
					}
				}
			}
			.partFooter{
				&#infos{
					width: 30%;
					p{
						color: #d3d3d3;
						font-size: 0.875vw;
						font-family: $robo;
						font-weight: 400;
						line-height: 1.5vw;
						margin: 0 0 4.8125vw;
					}
					span{
						position: relative;
						display: block;
						color: #fff;
						font-size: 1vw;
						font-family: $mont;
						font-weight: 300;
						line-height: 1.125vw;
						padding: 0 0 0 3.1875vw;
						svg{
							position: absolute;
							left: 0;
							top: 50%;
							transform: translate(0, -50%);
						}
						& + span{
							margin: 1.75vw 0 0 0;
						}
					}
				}
				&#miniSiteMap{
					width: 20%;
					position: relative;
					ul{
						display: table;
						margin: 0 auto;
						li{
							& + li{
								margin: 1.25vw 0 0 0;
							}
							a{
								color: #fff;
								font-size: 1.125vw;
								font-weight: 400;
								font-family: $robo;
								line-height: initial;
								transition-property: color;
								transition-duration: 0.4s;
								&:hover{
									color: $red;
								}
							}
						}
						&#social{
							display: flex;
							justify-content: center;
							position: absolute;
							bottom: 0;
							left: 50%;
							transform: translate(-50%, 0);
							li{
								a{
									color: #fff;
									font-size: 1.5625vw;
									transition-property: color;
									transition-duration: 0.4s;
									&:hover{
										color: $red;
									}
								}
								& + li{
									margin: 0 0 0 2.25vw;
									a{

									}
								}
							}
						}
					}
				}
				&#links{
					width: 20%;
					ul{
						display: table;
						margin: 0 auto;
						li{
							& + li{
								margin: 1.25vw 0 0 0;
							}
							a{
								color: #fff;
								font-size: 1.125vw;
								font-weight: 400;
								font-family: $robo;
								line-height: initial;
								transition-property: color;
								transition-duration: 0.4s;
								&:hover{
									color: $red;
								}
							}
						}
					}
				}
				&#form{
					width: 30%;
					.gform_confirmation_message{
						color: #fff;
						font-family: $mont;
						font-weight: 400;
						font-size: 14px;
					}
					.gform_wrapper{
						form{
							.validation_error{
								display: none;
							}
							.gform_body{

								ul{

									li{
										& + li{
											margin: 0.75vw 0 0 0;
										}
										label{
											display: none;
										}
										.validation_message{
											display: none;
										}
										.ginput_container{

											input,
											textarea{
												background: #323232;
												width: 100%;
												height: 3.125vw;
												border: none;
												font-family: $robo;
												font-weight: 300;
												font-size: 1.125vw;
												padding: 0 1.25vw;
												color: #ffffff;
											}
											textarea{
												height: 172px;
												padding: 1.25vw;
											}
										}
										&.gfield_error{
											.ginput_container{

												input,
												textarea{
													border-bottom: 0.1875vw solid $red;
												}
											}
										}
									}
								}
							}
							.gform_footer{
								margin: 2.1875vw 0 0 0;
								&:after{
									display: table;
									content: "";
									clear: both;
								}
								input{
									background: #323232;
									border: 1px solid #787878;
									color: #fff;
									font-family: $robo;
									font-weight: 900;
									font-size: 0.75vw;
									letter-spacing: 0.2em;
									text-transform: uppercase;
									padding: 1.0625vw 2.5vw;
									margin: 0;
									float: right;
									transition-duration: 0.4s, 0.4s;
									transition-property: color, border-color;
									&:hover{
										color: $red;
										border-color: $red;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	#bottom{
		background: #323232;
		padding: 15px 0;
		.corps{
			display: flex;
			justify-content: space-between;
			width: 100%;
			height: 100%;

			p{
				color: #c3c3c3;
				font-size: 0.875vw;
				font-family: $mont;
				font-weight: 400;
				line-height: initial;
				a{
					color: #fff;
				}
			}
			a{
				color: #fff;
				font-size: 0.875vw;
				font-family: $robo;
				font-weight: 300;
				line-height: initial;
				transition-property: color;
				transition-duration: 0.4s;
				&:hover{
					color: $red;
				}
			}
		}
	}
}

.footer-hotlinks {
	font: 400 10px $mont;
	color: #c3c3c3;
}