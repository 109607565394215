#intro{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	.slick-vertical{
		height: 100% !important;
		.slick-list{
			height: 100% !important;

			.slick-track{
				height: 100% !important;
				.slick-slide{
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					position: relative;
					opacity: 0;
					height: 100% !important;
					transition-property: opacity;
					transition-duration: 0.4s;
					&:before{
						background-color: rgba(0, 0, 0, 0.1);
						position: absolute;
						display: block;
						content: "";
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

					}
					&.slick-current{
						opacity: 1;
					}
					.carouBox{
						background-color: rgba(60, 60, 59, .71);
						position: absolute;
						left: 25.081788440567067vh;
						bottom: 150px;
						.boxInt{
							width: 650px;
							padding: 30px 60px;
							i{
								color: $red;
								font-size: 3.271537622682661vh;
								float: left;
								width: 2.6172300981461287vh;
								padding: 0 0 0 2.1810250817884405vh;
							}
							h2{
								color: #fff;
								font-size: 3.271537622682661vh;
								font-family: $mont;
								font-weight: 700;
								line-height: 117%;
								text-transform: uppercase;
								float: left;
								width: calc(100% - 2.6172300981461287vh);
								padding: 0 0 0 3.489640130861505vh;
								&:after{
									background: $red;
									display: block;
									content: "";
									width: 5.561613958560524vh;
									height: 0.4362050163576881vh;
									margin: 3.271537622682661vh 0 2.1810250817884405vh -5.997818974918212vh;
								}
							}
							p{
								color: #fff;
								font-size: 14px;
								font-family: $mont;
								font-weight: 300;
								line-height: 1.5em;
								padding: 0 0 0 10px;
								clear: both;
							}
							.buttons{
								display: flex;
								margin: 2.1810250817884405vh 0 0 0;
								a{
									position: relative;
									color: #fff;
									font-size: 1.3086150490730644vh;
									font-family: $mont;
									font-weight: 700;
									letter-spacing: 0.2em;
									line-height: 100%;
									text-transform: uppercase;
									border: 0.3271537622682661vh solid #fff;
									padding: 1.6357688113413305vh 1.0905125408942202vh;
									display: table;
									transition-property: color;
									transition-duration: 0.4s;
									transition-delay: 0.4s;
									& + a{
										margin: 0 0 0 2.1810250817884405vh;
									}
									span{
										position: relative;
										z-index:2;
									}

									&:after{
										background: #fff;
										position: absolute;
										display: block;
										content: "";
										width: 0;
										height: 0.25vw;
										left: 50%;
										bottom: 0;
										opacity: 0;
										transform: translate(-50%, 0);
										transition-property: width, height, opacity;
										transition-duration: 0.4s, 0.4s, 0.4s;
										transition-delay: 0s, 0.4s, 0s;
										z-index: 0;
									}
									&:hover{
										color: $red;
										&:after{
											width: 101%;
											height: 101%;
											opacity: 1;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.slick-dotted{
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		width: 25.081788440567067vh;
		height: 100%;
		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
				.slick-slide{
					height: 100%;
					position: relative;
					span{
						position: absolute;
						display: table;
						top: 42.42093784078517vh;
						left: 50%;
						color: $red;
						font-size: 5.670665212649945vh;
						font-family: $mont;
						font-weight: 700;
						line-height: 100%;
						writing-mode: vertical-lr;
  						text-orientation: sideways;
  						transform: translate(-50%, 0);
						&:before{
							background: $red;
							position: absolute;
							display: table;
							content: "";
							width: 0.4362050163576881vh;
							height: 5.561613958560524vh;
							margin: 0 auto;
							top: -7.52453653217012vh;
							left: 50%;
							transform: translate(-50%, 0);
						}
					}
				}
			}
		}
		.slick-dots{
			bottom: 24.7546346782988vh;
			text-align: initial;
			display: table;
			width: auto;
			left: 50%;
			transform: translate(-50%, 0);
			li{
				background: #fff;
				display: block;
				width: 1.0905125408942202vh;
				height: 1.0905125408942202vh;
				border-radius: 1.0905125408942202vh;
				margin: 0;
				& + li{
					margin: 2.5vw 0 0 0;
				}
				button{
					width: 1.0905125408942202vh;
					height: 1.0905125408942202vh;
					&:before {
						content: none;
					}
				}
				&.slick-active{
					background: $red;
					width: 1.0905125408942202vh;
					height: 1.0905125408942202vh; // 10px for 917 screen height
				}
			}
		}
	}
}



#services{
	background-color: #fff;
	z-index: 3;
	.corps{
		padding: 6vw 0 7.25vw 0;
		#serviceTable{

			#tableFirstPart{
				display: flex;
				height: 600px;
				#colUn,
				#colDeux,
				#colTrois{
					height: 100%;
				}
				#colUn{
					width: 25%;
					.contenu{
						background: #e4e4e4;
						height: 50%;
						padding: 30px;
						h3{
							color: #3c3c3b;
							font-size: 26px;
							font-family: $mont;
							font-weight: bold;
							line-height: initial;
							text-transform: uppercase;
							max-width: 170px;
							&:after{
								background: #3c3c3b;
								display: block;
								content: "";
								width: 40px;
								height: 5px;
								margin: 16px 0;
							}
						}
						p{
							color: #a0a0a0;
							font-size: 14px;
							font-family: $robo;
							font-weight: 300;
							line-height: 1.4em;
						}
					}
					.on{
						position: relative;
						height: 50% !important;
						.slick-slider{
							background-color: $red;
							height: 100% !important;
							.slick-arrow{
								top: initial;
								left: initial;
								right: initial;
								bottom: initial;
								transform: translate(-50%, 0);
								z-index: 2;
								opacity: 1;
								&:before{
									background-image: url(../images/arrow-slick.png);
									background-size: 100% 100%;
									background-repeat: no-repeat;
									background-position: center center;
									display: table;
									content: "";
									width: 1.375vw;
									height: 1.75vw;
									opacity: 1;
									transition-property: filter;
									transition-duration: 0.4s;
								}
								&:hover{
									&:before{
										filter: invert(100%);
									}	
								}
								&.slick-prev{
									bottom: 50px;
									left: 50%;
									transform: translate(-50%, 0) rotate(180deg);
								}
								&.slick-next{
									left: 50%;
									top: 50px;
								}
							}
							.slick-list{
								height: 100% !important;
								.slick-track{
									height: 100% !important;
									.slick-slide{
										position: relative;
										height: 100% !important;

										span{
											position: absolute;
											top: 50%;
											left: 42%;
											color: #fff;
											transform: translate(-50%, -50%);
											font-size: 2.25vw;
											font-family: $mont;
											font-weight: 400;
											line-height: 100%;
										}
									}
								}
							}
						}
					}
					.onNum{
						color: #fff;
						position: absolute;
						top: 50%;
						left: 58%;
						transform: translate(-50%, -50%);
						font-size: 1.25vw;
						font-family: $mont;
						font-weight: 400;
						line-height: 100%;
					}
				}
				#colDeux{
					position: relative;
					width: 50%;
					.slick-slider,
					.slick-list,
					.slick-track,
					.slick-slide {
						height: 100% !important;
					}
					.colDeuxContent {
						height: 100% !important;
					}
				}
				#colTrois{
					position: relative;
					width: 25%;
					z-index: 3;
					&:after{
						background: #fff;
						position: absolute;
						display: block;
						content: "";
						width: 21px;
						height: 21px;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%) rotate(45deg);
					}
					.bg{
						height: 50%;
						background-size: cover;
						background-repeat: no-repeat;
						background-position: center;
					}
					.contenu{
						height: 50%;
						padding: 1.75vw 1.9375vw;
						h4{
							transition: all 0.25s $easeinoutquad;
							color: #3c3c3b;
							font-size: 1.125vw;
							font-family: $mont;
							font-weight: bold;
							line-height: initial;
							text-transform: uppercase;
							max-width: 13.125vw;
							&:after{
								content: '';
								transition: all 0.25s $easeinoutquad;
								background: #3c3c3b;
								display: block;
								width: 40px;
								height: 5px;
								margin: 16px 0;
							}
						}
						p{
							color: #989898;
							font-size: 14px;
							font-family: $robo;
							font-weight: 300;
							line-height: 1.6em;
						}
					}
				}
			}
			#secondPart{
				display: flex;
				flex-wrap: wrap;
				#un{
					transition: all 0.25s $easeinoutquad;
					background: #e4e4e4;
					width: 25%;
					height: 300px;
					position: relative;
					&:hover {
						background-color: #bbb;
					}
					.int{
						position: absolute;
						display: block;
						width: 100%;
						top: 50%;
						left: 0;
						transform: translate(0, -50%);
						img{
							display: block;
							margin: 0 auto 1.25vw;
							padding-left: 14px;
							width: 72px;
							height: auto;
						}
						span{
							display: block;
							color: #5a5959;
							font-size: 0.75vw;
							font-family: $mont;
							font-weight: 700;
							letter-spacing: 0.2em;
							text-transform: uppercase;
							text-align: center;
						}
					}
				}
				#deux{
					transition: all 0.25s $easeinoutquad;
					background: #3c3c3b;
					width: 25%;
					height: 300px;
					position: relative;
					&:hover {
						background-color: #777;
					}
					.int{
						position: absolute;
						display: block;
						width: 100%;
						top: 50%;
						left: 0;
						transform: translate(0, -50%);
						img{
							display: block;
							margin: 0 auto 0.9375vw;
							width: 3.375vw;
							height: auto;
						}
						span{
							display: block;
							color: #fff;
							font-size: 0.75vw;
							font-family: $mont;
							font-weight: 400;
							letter-spacing: 0.2em;
							text-transform: uppercase;
							text-align: center;
						}
					}
				}
				#trois{
					background: $red;
					width: 50%;
					height: 300px;
					position: relative;
					.int{
						position: absolute;
						display: block;
						width: 100%;
						top: 50%;
						left: 0;
						transform: translate(0, -50%);
						form{
							position: relative;
							display: table;
							margin: 0 auto;
							width: 100%;
							max-width: calc(100% - 62px);

							input{
								&[type=text]{
									background: none;
									border: none;
									padding: 0.9375vw 0;
									color: #fff;
									border-bottom: 1px solid #fff;
									width: 100%;
									font-size: 1.125vw;
									font-family: $mont;
									font-weight: 400;
								}
								&[type=image]{
									position: absolute;
									top: 50%;
									right: 0;
									width: 1.1875vw;
									height: 1.1875vw;
									transform: translate(0, -50%);
								}
								&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
									color: #fff;
									font-size: 1.125vw;
									font-family: $mont;
									font-weight: 400;
								}
								&::-moz-placeholder { /* Firefox 19+ */
									color: #fff;
									font-size: 1.125vw;
									font-family: $mont;
									font-weight: 400;
								}
								&:-ms-input-placeholder { /* IE 10+ */
									color: #fff;
									font-size: 1.125vw;
									font-family: $mont;
									font-weight: 400;
								}
								&:-moz-placeholder { /* Firefox 18- */
									color: #fff;
									font-size: 1.125vw;
									font-family: $mont;
									font-weight: 400;
								}
							}
						}
					}
				}
				#quatre{
					width: 50%;
					height: 350px;
					padding: 50px 30px 0;
					.int{
						height: 100%;

						svg{
							display: block;
							width: 2.6875vw;
							height: 3.1875vw;
							margin: 0 auto;
						}
						span{
							display: block;
							color: #363636;
							font-size: 14px;
							font-family: $mont;
							font-weight: 700;
							width: 100%;
							text-align: center;
							margin: 20px 0 8px;
						}
						p{
							color: #a0a0a0;
							font-size: 14px;
							font-family: $robo;
							font-weight: 300;
							text-align: center;
							line-height: 1.5em;
						}
					}
				}
				#cinq{
					background: #e4e4e4;
					width: 50%;
					height: 350px;

					.int{
						padding: 50px 60px 0 30px;
						h3{
							color: #3c3c3b;
							font-size: 22px;
							font-family: $mont;
							font-weight: 700;
							text-transform: uppercase;
							max-width: 340px;
							&:after{
								background: #3c3c3b;
								display: block;
								content: "";
								width: 40px;
								height: 5px;
								margin: 20px 0 15px;
							}
						}
						p{
							color: #363636;
							font-size: 14px;
							font-family: $mont;
							font-weight: 400;
							line-height: 1.75em;
						}
						a{
							position: relative;
							display: inline-table;
							background: #363636;
							color: #fff;
							font-size: 0.625vw;
							font-family: $mont;
							font-weight: 400;
							text-transform: uppercase;
							letter-spacing: 0.2em;
							padding: 1.1875vw 2.1875vw;
							margin: 20px 0 0 0;
							transition-property: color;
							transition-duration: 0.4s;
							transition-delay: 0.4s;
							span{
								position: relative;
								z-index: 2;
							}
							&:after{
								background: $red;
								position: absolute;
								display: block;
								content: "";
								width: 0;
								height: 0.25vw;
								left: 50%;
								bottom: 0;
								opacity: 0;
								transform: translate(-50%, 0);
								transition-property: width, height, opacity;
								transition-duration: 0.4s, 0.4s, 0.4s;
								transition-delay: 0s, 0.4s, 0s;
								z-index: 0;
							}
							&:hover{
								color: #fff;
								&:after{
									width: 101%;
									height: 101%;
									opacity: 1;
								}
							}
						}
					}
				}
				#six{
					position: relative;
					width: 25%;
					height: 290px;
					border-top: 1px solid rgb(235, 235, 235);
					border-left: 1px solid rgb(235, 235, 235);
					border-bottom: 1px solid rgb(235, 235, 235);
					z-index: 3;
					&:after{
						background: #fff;
						position: absolute;
						display: block;
						content: "";
						width: 1.625vw;
						height: 1.625vw;
						top: 50%;
						right: -0.625vw;
						transform: translate(0, -50%) rotate(45deg);
					}
					.int{
						.slick-slider{
							height: 100%;
							.slick-list{
								height: 100%;
								.slick-track{
									height: 100%;
									.slick-slide{
										height: 100%;
										padding: 30px;
										h3{
											display: block;
											clear: both;
											color: #3c3c3b;
											font-size: 24px;
											font-family: $mont;
											font-weight: 700;
											text-transform: uppercase;
											line-height: initial;
											white-space: nowrap;
											overflow: hidden;
											text-overflow: ellipsis;
											&:after{
												background: #3c3c3b;
												display: block;
												content: "";
												width: 2.5vw;
												height: 0.3125vw;
												margin: 0.9375vw 0;
											}
										}
										p{
											color: #a0a0a0;
											font-size: 14px;
											font-family: $robo;
											font-weight: 300;
											line-height: 1.6em;
											display: block;
											clear: both;
										}
										span{
											color: #a0a0a0;
											font-size: 0.6875vw;
											font-family: $robo;
											font-weight: 300;
											line-height: 1.375vw;
											display: block;
											clear: both;
											margin: 0.1875vw 0 0.75vw;
										}
										a{
											position: relative;
											display: inline-block;
											border: 0.1875vw solid $red;
											font-size: 0.625vw;
											font-weight: 900;
											letter-spacing: 0.2em;
											padding: 0.9375vw 1.25vw;
											text-transform: uppercase;
											span{
												position: relative;
												z-index:2;
												color: $red;
												border: none;
												font-family: $mont;
												font-size: 10px;
												font-weight: 900;
											    line-height: initial;
											    display: block;
											    clear: both;
											    margin: 0;
											    transition-property: color;
												transition-duration: 0.4s;
												transition-delay: 0.4s;
											}
											&:after{
												background: $red;
												position: absolute;
												display: block;
												content: "";
												width: 0;
												height: 0.25vw;
												left: 50%;
												bottom: 0;
												opacity: 0;
												transform: translate(-50%, 0);
												transition-property: width, height, opacity;
												transition-duration: 0.4s, 0.4s, 0.4s;
												transition-delay: 0s, 0.4s, 0s;
												z-index: 0;
											}
											&:hover{
												color: #fff;
												span{
													color: #fff;
												}
												&:after{
													width: 101%;
													height: 101%;
													opacity: 1;
												}
											}
										}
									}
								}
							}
						}
					}
				}
				#sept{
					background: transparent;
					width: 50%;
					height: 290px;
					.int{
						height: 100%;
						.slick-slider{
							height: 100%;
							.slick-list{
								height: 100%;
								.slick-track{
									height: 100%;
									.slick-slide{
										background-size: cover;
										background-repeat: no-repeat;
										background-position: center center;
										height: 100%;
										padding: 0;
									}
								}
							}
						}
					}
				}
				#huit{
					background: #3c3c3b;
					width: 25%;
					height: 290px;
					.int{
						padding: 32px 2.1875vw;
						h3{
							display: block;
							clear: both;
							color: #fff;
							font-size: 24px;
							font-family: $mont;
							font-weight: 700;
							text-transform: uppercase;
							line-height: initial;
							&:after{
								background: #fff;
								display: block;
								content: "";
								width: 40px;
								height: 6px;
								margin: 16px 0 60px;
							}
						}
						.gform_confirmation_message{
							color: #fff;
							font-family: $mont;
							font-weight: 400;
							font-size: 0.875vw;
						}
						.gform_wrapper{
							form{
								.validation_error{
									display: none;
								}
								.gform_body{

									ul{

										li{

											label{
												display: none;
											}
											.validation_message{
												display: none;
											}
											.ginput_container{

												input{
													width: 100%;
													height: 2.75vw;
													border: none;
													font-family: $robo;
													font-weight: 300;
													font-size: 14px;
													padding: 0 14px;
												}
											}
											&.gfield_error{
												.ginput_container{

													input{
														border-bottom: 0.1875vw solid $red;
													}
												}
											}
										}
									}
								}
								.gform_footer{
									margin: 2.1875vw 0 0 0;
									&:after{
										display: table;
										content: "";
										clear: both;
									}
									input{
										background: transparent;
										border: none;
										color: #fff;
										font-family: $mont;
										font-weight: 400;
										font-size: 0.625vw;
										letter-spacing: 0.2em;
										text-transform: uppercase;
										padding: 0;
										margin: 0;
										float: right;
										transition-duration: 0.4s;
										transition-property: color;
										&:hover{
											color: $red;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.colDeuxContentLane{
	height: 50%;
	&.is--first {
		.contentLaneBgs {
			&:after{
				left: 100%;
			}
		}
		.contentLaneInts {
			float: right;
		}
	}
	&:after{
		display: table;
		content: "";
		clear: both;
	}
	.contentLaneBgs {
		position: relative;
		overflow: hidden;
		float: left;
		height: 100%;
		width: 50%;
		&:after{
			background: #fff;
			position: absolute;
			display: block;
			content: "";
			width: 21px;
			height: 21px;
			top: 50%;
			left: 0;
			transform: translate(-50%, -50%) rotate(45deg);
		}
	}
	.contentLaneBg {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: right center;
		position: relative;
		height: 100%;
		width: 100%;
	}
	.contentLaneInts {
		position: relative;
		overflow: hidden;
		float: left;
		height: 100%;
		width: 50%;
	}
	.contentLaneInt {
		background-color: #FFF;
		display: flex;
		align-items: center;
		position: relative;
		overflow: hidden;
		height: 100%;
		.contentLaneIntWrap {
			padding: 20px 30px;
			width: 100%;
		}
		h4 {
			transition: all 0.25s $easeinoutquad;
			color: #3c3c3b;
			font-size: 1.125vw;
			font-family: $mont;
			font-weight: bold;
			line-height: initial;
			text-transform: uppercase;
			max-width: 13.125vw;
			&:after {
				content: '';
				transition: all 0.25s $easeinoutquad;
				background: #3c3c3b;
				display: block;
				width: 40px;
				height: 5px;
				margin: 16px 0;
			}
		}
		p {
			color: #989898;
			font-size: 14px;
			font-family: $robo;
			font-weight: 300;
			line-height: 1.6em;
		}
	}
}
.intMore {
	transition: all 0.25s $easeinoutquad;
	display: block;
	margin: 16px 0 0;
}

.link--full-size {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9;
	&:hover, &:active, &:focus {
		& ~ h4 {
			color: #888 !important;
			&:after {
				background-color: #888 !important;
			}
		}
		& ~ .intMore {
			transform: translateX(10px);
		}
	}
}

.testim {

	.slick-next::before, .slick-prev::before {
		color: #333;
	}
}

.testim-rating {
	text-align: center;
	margin: 0 0 5px;
	&.has--1 {
		.star:first-child {
			@extend .star--filled;
		}
	}
	&.has--2 {
		.star:first-child {
			&, & + .star {
				@extend .star--filled;
			}
		}
	}
	&.has--3 {
		.star:first-child {
			&, & + .star, & + .star + .star {
				@extend .star--filled;
			}
		}
	}
	&.has--4 {
		.star {
			@extend .star--filled;
			&:last-child {
				background-position: left top;
			}
		}
	}
	&.has--5 {
		.star {
			@extend .star--filled;
		}
	}
	.star {
		background: transparent url('../images/stars.png') no-repeat left top;
		background-size: 15px 28px;
		display: inline-block !important;
		width: 15px !important;
		height: 14px !important;
		overflow: hidden;
		margin: 0 1px 0 0 !important;
	}
}
.star--filled {
	background-position: left bottom;
}