#introEnt{
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 100vh;

	#introBox{
		background: rgba(60, 60, 59, .72);
		position: absolute;
		bottom: 19.520174482006542vh;
		left: 24.536532170119955vh;
		width: 76.66303162486369vh;
		min-height: 38.276990185387135vh;
		padding: 3.816793893129771vh 0 2.7262813522355507vh 11.34133042529989vh;
		i{
			color: $red;
			font-size: 3.271537622682661vh;
			float: left;
			width: 2.6172300981461287vh;
			padding: 0 0 0 2.1810250817884405vh;
		}
		h2{
			color: #fff;
			font-size: 3.271537622682661vh;
			font-family: $mont;
			font-weight: 700;
			line-height: 100%;
			text-transform: uppercase;
			float: left;
			width: 50%;
			max-width: 35.28898582333697vh;
			margin: 0 auto;
			padding: 0 0 0 3.489640130861505vh;
			&:after{
				background: $red;
				display: block;
				content: "";
				width: 5.561613958560524vh;
				height: 0.4362050163576881vh;
				margin: 10px 0 10px -45px;
			}
		}
		p{
			color: #fff;
			font-size: 1.5267175572519085vh;
			font-family: $mont;
			font-weight: 300;
			line-height: 2.6172300981461287vh;
			padding: 0 0 0 2.0719738276990185vh;
			max-width: 55.28898582333697vh;
			clear: both;
		}
		.buttons{
			display: flex;
			margin: 2.1810250817884405vh 0 0 2.0719738276990185vh;
			a{
				position: relative;
				color: #fff;
				font-size: 1.3086150490730644vh;
				font-family: $mont;
				font-weight: 700;
				letter-spacing: 0.2em;
				line-height: 100%;
				text-transform: uppercase;
				border: 0.3271537622682661vh solid #fff;
				padding: 1.6357688113413305vh 2.0905125408942202vh;
				display: table;
				transition-property: color;
				transition-duration: 0.4s;
				transition-delay: 0.4s;
				& + a{
					margin: 0 0 0 2.1810250817884405vh;
				}
				span{
					position: relative;
					z-index:2;
				}

				&:after{
					background: #fff;
					position: absolute;
					display: block;
					content: "";
					width: 0;
					height: 0.25vw;
					left: 50%;
					bottom: 0;
					opacity: 0;
					transform: translate(-50%, 0);
					transition-property: width, height, opacity;
					transition-duration: 0.4s, 0.4s, 0.4s;
					transition-delay: 0s, 0.4s, 0s;
					z-index: 0;
				}
				&:hover{
					color: $red;
					&:after{
						width: 101%;
						height: 101%;
						opacity: 1;
					}
				}
			}
		}
	}
}

#a-propos{
	padding: 5.5625vw 0 6.4375vw 0;
	#wrap{
		background: #f5f5f5;
		width: 100%;
		max-width: 85.375vw;
		margin: 0 auto;
		padding: 6.1875vw 0 12.1875vw;
		#top{
			margin: 0 0 6.5625vw 0;
			text-align: center;
			h2{
				color: $red;
				font-size: 2.25vw;
				font-family: $robo;
				font-weight: 700;
				line-height: initial;
				margin: 0 0 1.25vw;
			}
			h3{
				color: $red;
				font-size: 2.25vw;
				font-family: $robo;
				font-weight: 300;
				line-height: initial;
			}
			&:after{
				background: $red;
				display: block;
				content: "";
				width: 6.5vw;
				height: 1px;
				margin: 4.6875vw auto 0;
			}
		}
		#list{
			max-width: 73.4375vw;
			margin: 0 auto;
			.point{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				text-align: right;
				.part{
					width: calc(50% - 3.125vw);
					position: relative;
					.img{
						width: 100%;
						img{
							display: block;
							width: 4.8125vw;
							height: auto;
							float: right;
							margin: 0 0 1.25vw;
						}
						&:after{
							display: table;
							content: "";
							clear: both;
						}
					}
					h1{
						color: $red;
						font-size: 1.625vw;
						font-family: $mont;
						margin: 0 0 1.25vw;
						font-weight: 600;
						line-height: initial;
						text-transform: uppercase;
					}
					p{
						color: #869ea8;
						font-size: 1vw;
						font-family: $mont;
						font-weight: 500;
						line-height: 1.4375vw;
					}
					span{
						position: absolute;
						display: block;
						color: $red;
						font-size: 2.5vw;
						font-family: $mont;
						font-weight: 300;
						line-height: initial;
						text-transform: uppercase;
						width: 9.5vw;
						height: 9.5vw;
						border: 2px solid $red;
						border-radius: 9.5vw;
						line-height: 9.5vw;
						text-align: center;
						margin: 0 0 0 11vw;
						&:after{
							background: $red;
							position: absolute;
							top: 50%;
							left: -11vw;
							display: block;
							content: "";
							width: 9.125vw;
							height: 2px;
							margin: 0;
							transform: translate(0, -50%);
						}
					}

					.tooltip-container {
						position:absolute; 
						line-height: 8vw;
						text-align: center;
						margin: 0 0 0 11vw;
						background-position: center;
						background-size: cover;
						top: 0;
						left: -11.15vw;
						right: 0;
						bottom: 0;
					}

					.tooltip-container::after {
						border: none;
						background: transparent !important;
					}

					.tooltip-container img {
						width: 7.5vw;
						height: 7.5vw;
						border-radius: 8vw;
						position:relative; 
						width: 9.5vw;
						height: 9.5vw;
					}
				}
				&:nth-child(2n + 2){
					flex-direction: row-reverse;
					text-align: left;
					.img{
						width: 100%;
						img{
							display: block;
							width: 4.8125vw;
							height: auto;
							float: left;
						}
						&:after{
							display: table;
							content: "";
							clear: both;
						}
					}
					span{
						position: relative;
						display: block;
						color: $red;
						font-size: 2.5vw;
						font-family: $mont;
						font-weight: 300;
						line-height: initial;
						text-transform: uppercase;
						width: 9.5vw;
						height: 9.5vw;
						border: 2px solid $red;
						border-radius: 9.5vw;
						line-height: 9.5vw;
						text-align: center;
						float: right;
						margin: 0 11vw 0 0;
						&:after{
							background: $red;
							position: absolute;
							top: 50%;
							left: initial;
							right: -11vw;
							display: block;
							content: "";
							width: 9.125vw;
							height: 2px;
							margin: 0;
							transform: translate(0, -50%);
						}
					}
				}
			}
		}
	}
}