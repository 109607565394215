#viewServices{
	margin: 8.375vw 0 0;
	transition-property: margin, height;
	 n-duration: 0.4s, 0.4s;
	.service{
		display: flex;
		height: calc(100vh - 8.375vw);
		min-height: 710px;
		transition-duration: 0.4s;
		transition-property: height;
		.partService{
			position: relative;
			width: 50%;
			.bgOppo{
				width: 100%;
				height: 100%;
				.slick-list{
					height: 100%;
					.slick-track{
						height: 100%;
						.slick-slide{
							height: 100%;
							background-repeat:no-repeat;
							background-size:cover;
						}
					}
				}
			}
			.contenu{
				position: absolute;
				width: 100%;
				max-width: 510px;
				top: 210px;
				right: 60px;
				left: initial;
				/*bottom: 120px;*/
				z-index: 3;
				transition-duration: 0.4s;
				transition-property: bottom;
				i{
					color: $red;
					font-size: 25px;
					float: left;
					padding: 0 0 0 20px;
					line-height: 35px;
					margin: 0 10px 0 0;

				}
				h1{
					color: #fff;
					font-size: 30px;
					font-family: $mont;
					font-weight: 700;
					text-transform: uppercase;
					&:after{
						background: $red;
						display: table;
						content: "";
						margin: 25px 0;
						width: 51px;
						height: 4px;
					}
				}
				p{
					color: #fff;
					font-size: 14px;
					font-family: $robo;
					font-weight: 400;
					line-height: 24px;
					padding: 0 0 0 20px;
					& + p{
						margin: 5px 0 0 0;
					}
				}
				img{
					display: block;
					width: auto;
					height: 57px;
					margin: 65px 0 0 20px;
				}
				a{
					display: table;
					padding: 10px 16px;
					border: 3px solid $red;
					color: $red;
					font-size: 11px;
					font-family: $mont;
					font-weight: 700;
					letter-spacing: 0.15em;
					text-transform: uppercase;
					margin: 52px 0 0 20px;
					position: relative;
					transition-property: color;
					transition-duration: 0.4s;
					transition-delay: 0.4s;
					span{
						position: relative;
						z-index:2;
					}
					// &:before{
					// 	position: relative;
					// 	content: "\f178";
					// 	color: $red;
					// 	z-index: 3;
					// 	transition-property: color;
					// 	transition-duration: 0.4s;
					// 	transition-delay: 0.4s;
					// }
					&:after{
						background: $red;
						position: absolute;
						display: block;
						content: "";
						width: 0;
						height: 0.25vw;
						left: 50%;
						bottom: 0;
						opacity: 0;
						transform: translate(-50%, 0);
						transition-property: width, height, opacity;
						transition-duration: 0.4s, 0.4s, 0.4s;
						transition-delay: 0s, 0.4s, 0s;
						z-index: 0;
					}
					&:hover{
						color: #fff;
						&:before{
							color: #fff;
						}
						&:after{
							width: 101%;
							height: 101%;
							opacity: 1;
						}
					}
				}
			}
			.blocSlideGal {
				width: 31px;
				height: 31px;
			}
			.bottom{
				position: absolute;
				left: 90px;
				bottom: 39px;
				width: 100%;
				max-width: 490px;
				height: 13.849509269356597vh;
				.scroll{
					transform: initial;
					left: initial;
					top: initial;
					bottom: 0;
					right: initial;
					margin-left: -60px;
				}
				.gallerieButton{
					width: 29px;
					height: 29px;
					cursor: pointer;
					&:before,
					&:after {
						content: '';
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 29px;
						display: block;
						width: 29px;
						height: 29px;
					}
					&:before {
						background-image: url(../images/camera-services.png);
					}
					&:after {
						background-image: url(../images/camera-services-white.png);
						display: none;
					}
					&:hover, &:active, &:focus {
						&:before {
							display: none;
						}
						&:after {
							display: block;
						}
					}
					&.is--empty {
						&:before, &:after { content: none; }
						pointer-events: none;
						cursor: default;
					}
				}
				.partServiceNav{
					position: absolute;
					top: 50%;
					right: 0;
					width: 16px;
					height: 100%;
					transform: translate(0, -50%);
					.servicePrev{
						background-image: url(../images/arrow-slick.png);
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						position: absolute;
						bottom: 0;
						width: 16px;
						height: 19px;
						cursor: pointer;
						transform: rotate(180deg);
					}
					.num{
						position: absolute;
						display: flex;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						width: 70px;
						text-align: center;
						.textSlideNum{
							display: initial !important;
							width: 50%;
							span{
								color: #fff;
								font-family: $mont;
								font-size: 23.67px;
								line-height: initial;
							}
						}
						.onNum{
							display: block;
							width: 50%;
							color: #fff;
							font-family: $mont;
							font-size: 16px;
							line-height: initial;
						}
					}
					.serviceNext{
						background-image: url(../images/arrow-slick.png);
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						width: 16px;
						height: 19px;
						position: absolute;
						top: 0;
						cursor: pointer;
					}
				}
			}
			.contenuB{
				position: absolute;
				width: 100%;
				max-width: 490px;
				top: 110px;
				left: 90px;
				right: initial;
				img{
					width: auto;
					height: 45px;
					display: table;
					margin: 0 0 55px;
				}
				.btn-subject {
					display: table;
					padding: 10px 16px;
					border: 3px solid #FFF;
					color: #FFF;
					font-size: 11px;
					font-family: $mont;
					font-weight: 700;
					letter-spacing: 0.15em;
					text-transform: uppercase;
					margin: 30px 0 0;
					position: relative;
					transition-property: color;
					transition-duration: 0.4s;
					transition-delay: 0.4s;
					span{
						position: relative;
						z-index:2;
					}
					&:after{
						background: #FFF;
						position: absolute;
						display: block;
						content: "";
						width: 0;
						height: 0.25vw;
						left: 50%;
						bottom: 0;
						opacity: 0;
						transform: translate(-50%, 0);
						transition-property: width, height, opacity;
						transition-duration: 0.4s, 0.4s, 0.4s;
						transition-delay: 0s, 0.4s, 0s;
						z-index: 0;
					}
					&:hover{
						color: $red;
						&:before{
							color: $red;
						}
						&:after{
							width: 101%;
							height: 101%;
							opacity: 1;
						}
					}
				}
				.textSlide{
					clear: both;
					.slick-arrow{
						top: initial;
						left: initial;
						right: initial;
						bottom: initial;
						transform: initial;
						z-index: 2;
						opacity: 1;
						&:before{
							background-image: url(../images/arrow-slick.png);
							background-size: 100% 100%;
							background-repeat: no-repeat;
							background-position: center center;
							display: table;
							content: "";
							width: 1.375vw;
							height: 1.75vw;
							opacity: 1;
							transition-property: filter;
							transition-duration: 0.4s;
						}
						&:hover{
							&:before{
								filter: invert(100%);
							}	
						}
						&.slick-prev{
							bottom: 0;
							right: 0;
							transform: rotate(180deg);
						}
						&.slick-next{
							right: 0;
						}
					}
					.slick-list{

						.slick-track{

							.slick-slide{

								h3{
									color: #fff;
									font-size: 24px;
									font-family: $robo;
									font-weight: 400;
									margin: 0 0 15px;
								}
								p{
									color: #fff;
									font-size: 14px;
									font-family: $robo;
									font-weight: 400;
									line-height: 24px;
									& + p{
										margin: 18px 0 0 0;
									}
								}
							}
						}
					}
				}
			}
			&:nth-child(1){
				&:after{
					background: rgba(0, 0, 0, 0.4);
					position: absolute;
					display: block;
					content: "";
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			&:nth-child(2){
				background: $red;
			}
		}
		&:nth-child(2n + 2){
			flex-direction: row-reverse;
			.contenu{
				position: absolute;
				width: 100%;
				max-width: 510px;
				top: 240px;
				left: 63px;
				right: initial;
				/*bottom: 120px;*/
			}
			.blocSlideGal,
			.btn-subject {
				margin-left: auto !important;
			}
			.bottom{
				position: absolute;
				left: initial;
				right: 71px;
				bottom: 39px;
				width: 100%;
				height: 80px;
				.scroll{
					transform: initial;
					left: initial;
					top: initial;
					bottom: 0;
					right: 0;
				}
				.partServiceNav{
					position: absolute;
					top: 50%;
					right: initial;
					left: 63px;
					width: 16px;
					height: 100%;
					transform: translate(0, -50%);
					.servicePrev{
						background-image: url(../images/arrow-slick.png);
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						position: absolute;
						bottom: 0;
						width: 16px;
						height: 19px;
						cursor: pointer;
						transform: rotate(180deg);
					}
					.serviceNext{
						background-image: url(../images/arrow-slick.png);
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100% 100%;
						width: 16px;
						height: 19px;
						position: absolute;
						top: 0;
						cursor: pointer;
					}
				}
			}
			.contenuB{
				position: absolute;
				width: 100%;
				max-width: 490px;
				top: 130px;
				right: 71px;
				left: initial;
				text-align: right;
				img{
					margin-left: auto;
				}
				a {
					color: #FFF;
					&:hover, &:active, &:focus {
						text-decoration: underline;
					}
				}
			}
		}
	}
	&.active{
		margin: 8.375vw 0 0;
		.service{
			height: calc(100vh - 8.375vw);
		}
	}
}

.panelGallerieButton {
	transition: all 0.25s $easeinoutquad;
	background: rgba(0, 0, 0, .75);
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999;
	&.active {
		display: block;
		top: 0;
	}
}
.panelGallerieButton {
	.showGal,
	.slick-list,
	.slick-track {
		height: 100%;
	}
	.slick-slide {
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.slick-prev {
		left: 25px !important;
		padding: 19px 22px 19px 16px;
		&:before {
			transform: rotate(180deg);
		}
	}
	.slick-next {
		right: 25px !important;
		padding: 19px 16px 19px 22px;
	}
	.slick-prev,
	.slick-next {
		transition: all 0.25s $easeinoutquad;
		z-index: 9;
		width: 60px;
		height: 60px;
		background-color: #FFF;
		border-radius: 50%;
		&:before{
			content: '';
			transition: all 0.25s $easeinoutquad;
			background-image: url(../images/arrow-slick.png);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center center;
			display: table;
			content: "";
			width: 22px;
			height: 22px;
			margin: 0 auto;
			opacity: 1;
		}
		&:hover{
			background-color: #333;
			&:before{
				filter: invert(100%);
			}	
		}
	}
	.panelClose {
		transition: all 0.25s $easeinoutquad;
		background-color: #FFF;
		position: fixed;
		top: 0;
		right: 0;
		z-index: 99;
		cursor: pointer;
		padding: 6px 16px;
		font-size: 24px;
		font-weight: 700;
		font-family: $mont;
		text-transform: uppercase;
		color: #333;
		&:hover, &:active, &:focus {
			background-color: #333;
			color: #FFF;
		}
	}
}