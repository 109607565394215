/**
 * Laptop large
 */

@media all and (max-width: 1320px) {
  // Global
  .corps {
    max-width: 1000px;
  }
  header {
    &, &.active {
      nav ul#headMenu {
        li ul {
          min-width: 310px !important;
          padding: 10px 0;
          li a {
            font-size: 9px !important;
            padding: 12px 26px 12px 20px;
          }
        }
      }
    }
    nav {
      ul {
        &#social {
          li {
            & + li {
              padding: 0 0 0 14px !important;
            }
            a {
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  // Equipe & Rea
  #introRea,
  #introTeam {
    #introInt {
      #contenu {
        max-width: 300px;
        h2 {
          font-size: 20px;
          max-width: none;
          &:after {
            margin: 10px 0 10px -20px;
          }
        }
        p {
          font-size: 13px;
          line-height: 1.4em;
        }
        .buttons {
          margin: 15px 0 0;
          a {
            font-size: 10px;
            padding: 8px 12px;
          }
        }
      }
    }
  }
  #introRea {
    #introInt {
      #contenu {
        p {
          max-width: none;
        }
      }
    }
  }

  // Realisations
  #realisations {
    #wrap {
      h1 {
        font-size: 22px;
        line-height: 1.3em;
        margin-bottom: 30px;
      }
      #projets {
        #cat {
          max-width: 400px;
          .service {
            span {
              font-size: 10px;
            }
            img {
              margin-top: 10px;
              height: 30px;
            }
          }
        }
      }
    }
    #projets p,
    p {
      font-size: 14px !important;
      line-height: 1.5em !important;
      margin-top: 20px !important;
    }
    p {
      strong {
        font-size: 15px !important;
        line-height: 1.5em !important;
      }
    }
  }
  #cat-grid {
    .element-item {
      width: 33.333%;
      height: 340px;
    }
  }

  // Realisation (single)
  #reaInfo {
    #wrap {
      #top {
        &:after {
          width: 40px;
          margin: 20px 0 20px -30px;
        }
        h1, span span {
          font-size: 25px;
        }
        span {
          font-size: 15px;
        }
      }
      #bottom {
        #first {
          p {
            font-size: 14px;
            line-height: 1.5em;
          }
        }
        div a {
          font-size: 10px;
          padding: 12px 12px 11px;
        }
      }
    }
  }
  #moreRea #bottom .slick-slider .slick-list,
  #moreRea #top #wrap {
    max-width: 800px;
  }
  #moreRea #bottom .slick-slider .slick-arrow {
    width: 50px;
    height: 50px;
    &:before {
      width: 9px;
      height: 15px;
    }
  }
  #moreRea #bottom .slick-slider .slick-list .slick-track .slick-slide {
    height: 180px;
    .hover {
      background-size: 23px 23px;
      background-position: 50% 25%;
      padding: 10px;
      h5 {
        font-size: 11px;
        margin-bottom: 5px;
      }
      span {
        font-size: 9px;
        span {
          font-size: 11px;
        }
      }
    }
  }
  #moreRea {
    #top {
      #wrap {
        h1 {
          font-size: 22px;
          margin-bottom: 30px;
        }
        p {
          font-size: 14px;
          line-height: 1.5em;
        }
      }
    }
  }

  // Entreprise
  #introEnt {
    #introBox {
      width: 520px;
      min-height: 0;
      padding: 25px 40px;
      h2 {
        max-width: 330px;
        font-size: 22px;
        line-height: 1.4em;
        padding: 0 0 10px 10px;
      }
      i {
        padding: 6px 0 0;
        width: 16px;
      }
      p {
        padding: 0;
        max-width: none;
        font-size: 14px;
        line-height: 1.5em;
      }
      .buttons {
        margin: 10px 0;
        a {
          padding: 8px 12px;
          font-size: 10px;
        }
      }
    }
  }
  #a-propos #wrap #top {
    &:after {
      width: 60px;
      margin-top: 40px;
    }
    h2,
    h3 {
      font-size: 23px;
    }
  }
  #a-propos #wrap #list {
    .point {
      padding-bottom: 30px;
      &:nth-child(2n+2) span {
        margin: 0 100px 0 0 !important;
        &:after {
          right: -100px !important;
          left: auto !important;
        }
      }
      .part {
        span {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          line-height: 100px;
          margin: 0 0 0 100px;
          &:after {
            left: -100px;
            width: 80px !important;
          }
        }
        p {
          font-size: 13px;
          line-height: 1.5em;
        }
        .img img {
          width: 50px;
          margin-bottom: 20px;
        }
        h1, span {
          font-size: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  // Services
  #viewServices {
    .service {
      .partService {
        .contenu {
          width: 570px;
          max-width: 100%;
          left: 0;
          padding-left: 40px;
          padding-right: 20px;
        }
        .contenuB {
          width: 550px;
          max-width: 100%;
          left: auto;
          right: 0;
          padding-right: 40px;
          padding-left: 30px;
        }
        .bottom {
          height: 80px;
          max-width: 100%;
          width: 100%;
          padding-right: 40px;
          padding-left: 20px;
        }
        & + .partService {
          .bottom {
            left: auto;
            right: 0;
            padding-right: 20px;
            padding-left: 40px;
          }
        }
        .bottom {
          .partServiceNav {
            right: 60px;
          }
        }
      }
      &:first-child {
        .partService {
          & + .partService {
            .bottom {
              padding-left: 100px;
            }
          }
        }
      }
    }
  }

  // Contact
  #formCont #wrap {
    max-width: none;
    padding: 60px;
    #left {
      #top {
        max-width: 490px;
        h1 {
          font-size: 24px;
        }
        p {
          font-size: 14px;
          margin: 30px 0 20px;
          padding: 0;
        }
      }
      .p {
        padding: 0 100px 0 0;
        font-size: 14px;
      }
      #listServices {
        margin: 30px 0 0;
        max-width: 350px;
        .service {
          span {
            font-size: 12px;
          }
          img {
            width: 40px;
            margin: 10px auto;
          }
        }
      }
    }
    #right {
      padding-left: 20px;
    }
    #right .gform_wrapper form .gform_body ul li {
      margin: 20px 0 0;
    }
    #right .gform_wrapper form .gform_body ul li.userIcon .ginput_container input,
    #right .gform_wrapper form .gform_body ul li.userIcon .ginput_container select,
    #right .gform_wrapper form .gform_body ul li.userIcon .ginput_container textarea,
    #right .gform_wrapper form .gform_body ul li.targetIcon .ginput_container input,
    #right .gform_wrapper form .gform_body ul li.targetIcon .ginput_container select,
    #right .gform_wrapper form .gform_body ul li.targetIcon .ginput_container textarea,
    #right .gform_wrapper form .gform_body ul li.phoneIcon .ginput_container input,
    #right .gform_wrapper form .gform_body ul li.phoneIcon .ginput_container select,
    #right .gform_wrapper form .gform_body ul li.phoneIcon .ginput_container textarea,
    #right .gform_wrapper form .gform_body ul li.mailIcon .ginput_container input,
    #right .gform_wrapper form .gform_body ul li.mailIcon .ginput_container select,
    #right .gform_wrapper form .gform_body ul li.mailIcon .ginput_container textarea,
    #right .gform_wrapper form .gform_body ul li.noteIcon .ginput_container input,
    #right .gform_wrapper form .gform_body ul li.noteIcon .ginput_container select,
    #right .gform_wrapper form .gform_body ul li.noteIcon .ginput_container textarea {
      background-size: 14px auto;
    }
    #right .gform_wrapper form .gform_body ul li .ginput_container input,
    #right .gform_wrapper form .gform_body ul li .ginput_container select,
    #right .gform_wrapper form .gform_body ul li .ginput_container textarea {
      font-size: 13px;
    }
    #right .gform_wrapper form .gform_footer input {
      font-size: 10px;
      padding: 12px 30px 10px;
    }
    #right .gform_wrapper form .gform_body ul li:last-child .ginput_container input,
    #right .gform_wrapper form .gform_body ul li:last-child .ginput_container select,
    #right .gform_wrapper form .gform_body ul li:last-child .ginput_container textarea {
      height: 160px;
    }
  }
}



/**
 * Laptop
 */

 @media all and (max-width: 1500px) {
   .year-badge {
            display: none;
          }
 }

@media all and (max-width: 1120px) {
  #wrapPage header {
    &, &.active {
      #logo {
        img {
          width: 130px !important;
          height: 37px !important;
        }
      }
    }
  }

 
}

@media all and (max-width: 1040px) {
  // Global
  .corps {
    max-width: 720px;
  }
  header {
    nav {
      ul {
        &#social {
          margin-left: 10px !important;
        }
      }
    }
    &.active {
      nav {
        ul#headMenu {
          li a {
            font-size: 12px;
          }
        }
      }
    }
  }
  footer #top .corps {
    .partFooter#infos {
      p {
        font-size: 12px;
        line-height: 1.5em;
        margin-bottom: 40px;
      }
      span {
        font-size: 12px;
        line-height: 1.5em;
      }
    }
    .partFooter#links ul li a {
      font-size: 12px;
    }
    .partFooter#miniSiteMap ul li a {
      font-size: 12px;
    }
  }
  footer #top .corps .partFooter#form .gform_wrapper form .gform_body ul li .ginput_container input,
  footer #top .corps .partFooter#form .gform_wrapper form .gform_body ul li .ginput_container textarea,
  footer #top .corps .partFooter#form .gform_wrapper form .gform_footer input {
    font-size: 12px;
  }
  footer #bottom .corps a, 
  footer #bottom .corps p {
    font-size: 10px;
  }

  // Home
  #services .corps #serviceTable {
    #tableFirstPart {
      display: block;
      height: auto;
      #colUn {
        width: 100%;
        display: flex;
        .contenu {
          padding: 20px 30px;
        }
        .contenu,
        .on {
          width: 50%;
          height: 200px !important;
        }
      }
      #colDeux {
        width: 100%;
        height: auto;
      }
      #colTrois {
        width: 100%;
        display: flex;
        .contenu,
        .bg {
          height: 300px;
          width: 50%;
        }
        .contenu {
          padding: 30px;
        }
      }
    }
    #secondPart {
      #un {
        height: 200px;
      }
      #deux {
        height: 200px;
        .int img {
          width: 44px;
          margin: 0 auto 1.25vw;
        }
      }
      #un .int span,
      #deux .int span {
        font-size: 9px;
      }
      #trois {
        height: 200px;
      }
      #quatre {
        width: 100%;
        height: auto;
        padding-bottom: 70px;
      }
      #cinq {
        width: 100%;
        height: auto;
        padding-bottom: 50px;
        .int a {
          font-size: 9px;
          padding: 10px 20px;
        }
      }
      #six {
        .int .slick-slider .slick-list .slick-track .slick-slide span {
          font-size: 10px;
          line-height: 1.5em;
        }
      }
      #six,
      #sept {
        width: 50%;
      }
      #huit {
        width: 100%;
        height: auto;
        .int .gform_wrapper form .gform_footer input {
          font-size: 10px;
        }
      }
    }
  }
  #services .corps #serviceTable #tableFirstPart #colTrois .contenu h4,
  .colDeuxContentLane .contentLaneInt h4 {
    max-width: none;
  }
  .colDeuxContentLane {
    height: 300px;
  }
  #services .corps #serviceTable #tableFirstPart #colTrois .contenu h4,
  .colDeuxContentLane .contentLaneInt h4 {
    font-size: 14px;
  }

  // Equipe
  #equipes #wrap {
    .equipe {
      h2 {
        font-size: 22px;
      }
      .listes {
        .membre {



          &.notAdmin {
            padding: 30px 20px;
          }
          .profilPicture {
            width: 70px;
            height: 70px;
            border-radius: 50%;
          }
          .profilPictureAdmin {
            width: 90px;
            height: 90px;
            border-radius: 50%;
            margin-bottom: 20px;
          }
          .contenu {
            padding: 0 0 0 15px;
            h4 {
              font-size: 16px;
              margin-bottom: 2px;
            }
            span {
              font-size: 12px;
            }
          }
          .contenuAdmin {
            span:nth-child(2) {
              font-size: 12px;
            }
            span:nth-child(4) {
              font-size: 12px;
              margin: 6px 0 0;
            }
            .contactEquipe {
              position: absolute;
              top: 10px;
              right: 10px;
              margin: 0;
            }
            h3 {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  #nouveau {
    h2 {
      font-size: 22px;
      line-height: 1.2em;
      img {
        width: 25px;
        height: auto;
      }
    }
    .truc .contenu {
      height: 400px;
    }
  }

  // Services
  #viewServices {
    .service {
      .partService {
        .contenuB {
          top: auto;
          bottom: 160px;
        }
      }
    }
  }

  // Realisations
  #cat-grid {
    .element-item {
      width: 50%;
    }
  }

  // Contact
  #introContact {
    #boxContact {
      left: 0;
      bottom: 0;
      padding: 20px;
      i {
        left: 20px;
      }
      h2 {
        font-size: 15px;
        max-width: none;
        float: none;
        padding: 0 0 0 6px;
      }
      .span {
        font-size: 11px;
        line-height: 1.4em;
        padding-left: 20px;
        svg {
          width: 10px !important;
          height: 14px !important;
        }
      }
      .buttons {
        margin: 10px 0 0 0;
        a {
          font-size: 10px;
          padding: 8px 16px;
        }
      }
    }
  }
  #infosContact {
    height: auto;
    display: table;
    #left,
    #right {
      display: table-cell;
      #int {
        padding: 60px 40px 60px;
        width: auto;
      }
    }
  }
}

@media all and (min-width: 941px) {
  .toggle-menu {
    display: none;
  }
}

@media all and (max-width: 940px) {
  #wrapPage header {
    height: 60px !important;
    &, &.active {
      #logo {
        float: left;
        margin-left: 30px;
        margin-top: 11px;
      }
      nav, #logo {
        transform: none;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
      }
    }
    &, &.active, &.isSpecialPage {
      nav {
        margin-right: 30px;
      }
    }
    nav {
      float: right;
      margin-top: 19px;
      height: auto !important;
      ul#headMenu {
        transition: all 0.25s $easeinoutquad;
        background-color: rgba(60,60,59,.9);
        position: fixed;
        top: 120%;
        left: 0;
        z-index: 999;
        width: 100% !important;
        height: calc(100% - 60px) !important;
        right: 0 !important;
        display: block;
        float: none;
        overflow-x: hidden;
        overflow-y: visible;
        &.is--active {
          top: 60px;
        }
        li {
          display: block;
          height: auto;
          border: 0;
          a {
            font-size: 12px !important;
            height: 40px;
            padding: 11px 10px 0;
            border-bottom: 1px solid #888;
            color: #FFF !important;
            &:hover, &:active, &:focus {
              background-color: #c2010c;
              color: #FFF;
            }
          }
          ul {
            background: none;
            display: block !important;
            position: relative !important;
            top: 0 !important;
            left: 0 !important;
            padding: 0;
            border-bottom: 1px solid #888;
            float: none !important;
            &:before,
            &:after {
              content: none;
            }
            li {
              width: 100%;
              border: 0;
              &:last-child {
                a {
                  border-bottom: 0;
                }
              }
              &:after {
                content: none;
              }
              a {
                text-align: center;
                font-size: 9px !important;
                padding-top: 13px !important;
                &:hover, &:active, &:focus {

                }
              }
            }
          }
        }
      }
    }
  }

  // Realisation (single)
  #reaInfo {
    padding: 60px 0 160px;
  }
  #gallerie #wrap,
  #reaInfo #wrap {
    max-width: none;
    margin: 0 30px;
  }
  #projectList.slick-slider {
    padding: 0 60px;
  }

  // Equipe
  #equipes #wrap,
  #nouveau #wrap {
    max-width: none;
    margin: 0 30px;
  }

  // Services
  #viewServices {
    margin-top: 60px;
    &.active {
      margin-top: 60px;
    }
    .service {
      display: block;
      height: auto !important;
      .partService {
        width: 100%;
        height: auto;
        .bgOppo {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          .slick-list .slick-track .slick-slide {
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
        .contenu {
          position: relative;
          top: auto;
          right: auto;
          bottom: auto;
          left: auto;
          width: 100%;
          padding: 80px 60px;
          img {
            max-width: calc(100% - 20px);
          }
        }
        .contenuB {
          position: relative;
          top: auto;
          bottom: auto;
          right: auto;
          left: auto;
          width: 100%;
          padding: 80px 60px 180px;
          text-align: left;
          img {
            margin-left: 0 !important;
          }
        }
        .btn-subject {
          margin-left: 0 !important;
        }
        .bottom {
          padding-right: 60px !important;
          padding-left: 60px !important;
          .scroll {
            display: none;
          }
        }
      }
    }
  }
}


/**
 * Tablet
 */

@media all and (max-width: 767px) {
  // Global
  .corps {
    max-width: 400px;
  }
  footer #top .corps #logoTop a img {
    width: 190px;
    height: 53px;
  }
  footer #top .corps .partFooter#infos {
    width: 50%;
    span {
      padding-left: 25px;
      svg {
        width: 10px !important;
        height: auto !important;
      }
    }
  }
  footer #top .corps .partFooter#miniSiteMap,
  footer #top .corps .partFooter#links {
    width: 25%;
  }
  footer #top .corps .partFooter#miniSiteMap {
    ul#social li a {
      font-size: 14px;
    }
  }
  footer #top .corps .partFooter#form .gform_wrapper form .gform_body ul li .ginput_container input {
    height: 30px;
  }
  footer #top .corps .partFooter#form {
    width: 100%;
    padding-top: 30px;
  }
  footer #bottom .corps {
    display: block;
  }
  .footer-hotlinks {
    padding-top: 8px;
  }

  // Home
  #intro .slick-dotted {
    width: 70px;
  }
  #intro .slick-dotted .slick-list .slick-track .slick-slide span {
    left: 40px;
  }
  #intro .slick-vertical .slick-list .slick-track .slick-slide .carouBox .boxInt .buttons a,
  #introSingRea .slick-vertical .slick-list .slick-track .slick-slide .carouBox .boxInt .buttons a {
    font-size: 9px;
    padding: 8px 12px;
  }
  #intro .slick-vertical .slick-list .slick-track .slick-slide {
    .carouBox {
      left: 70px;
      bottom: 130px;
      .boxInt {
        width: 400px;
        padding: 20px;
        p {
          font-size: 12px;
          padding-left: 0;
        }
      }
    }
  }
  #services .corps #serviceTable #tableFirstPart #colUn .on .slick-slider .slick-list .slick-track .slick-slide span,
  #services .corps #serviceTable #tableFirstPart #colUn .onNum {
    font-size: 28px;
  }
  #services .corps #serviceTable #tableFirstPart #colUn {
    .on {
      height: 100px!important;
      .slick-slider .slick-arrow.slick-next {
        top: 38%;
        left: 75%;
      }
      .slick-slider .slick-arrow.slick-prev {
        bottom: 38%;
        left: 25%;
      }
      .slick-slider .slick-arrow {
        height: 26px;
        &:before {
          width: 20px;
          height: 26px;
        }
      }
    }
    .onNum {
      font-size: 18px;
      padding-bottom: 3px;
    }
  }
  #services .corps #serviceTable {
    #tableFirstPart {
      #colUn {
        display: block;
        .contenu,
        .on {
          width: 100%;
        }
        .contenu {
          padding-top: 30px;
          padding-bottom: 30px;
          height: auto !important;
        }
      }
      #colTrois {
        &:after {
          content: none;
        }
        .contenu {
          width: 100%;
          height: 270px;
          padding-bottom: 0 !important;
        }
        .bg {
          display: none;
        }
      }
    }
    #secondPart {
      #un,
      #deux {
        width: 50%;
      }
      #trois {
        width: 100%;
        height: 140px;
        .int {
          form {
            input {
              &[type=image] {
                width: 14px;
                height: 14px;
              }
              &[type=text] {
                font-size: 14px;
              }
              &::-webkit-input-placeholder {
                font-size: 14px;
              }
              &::-moz-placeholder {
                font-size: 14px;
              }
              &:-ms-input-placeholder {
                font-size: 14px;
              }
              &:-moz-placeholder {
                font-size: 14px;
              }
            }
          }
        }
      }
      #quatre .int svg {
        width: 24px;
        height: auto;
      }
      #six {
        width: 100%;
        border: 0;
        .int .slick-slider .slick-list .slick-track .slick-slide a {
          padding: 8px 14px;
        }
      }
      #sept {
        width: 100%;
        height: 250px;
      }
      #huit {
        .int {
          padding: 40px 30px;
          .gform_wrapper form .gform_body ul li .ginput_container input {
            height: 30px;
          }
          .gform_wrapper form .gform_footer {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .contentLaneBgs {
    display: none;
  }
  .colDeuxContentLane {
    height: 240px;
    border-bottom: 1px solid #ccc;
    .contentLaneInts {
      width: 100%;
      float: none;
    }
  }

  // Entreprise
  #a-propos {
    padding: 0;
    #wrap {
      padding: 60px 20px;
      max-width: none;
      #list {
        max-width: none;
      }
    }
  }
  #introEnt {
    #introBox {
      left: 20px;
      bottom: 20px;
      max-width: 520px;
      width: 100%;
    }
    .scroll {
      display: none;
    }
  }

  // Equipe
  #introRea #introInt #contenu,
  #introTeam #introInt #contenu {
    background-color: #FFF;
    max-width: 360px;
    padding: 30px;
    right: 30px;
    i {
      font-size: 16px;
      width: 12px;
      left: 30px;
    }
  }
  #equipes #wrap {
    .equipe {
      .listes {
        .membre {
          width: calc(50% - 10px);
          padding: 20px;
          margin-bottom: 20px;
          .contenuAdmin {
            .contactEquipe img {
              height: 14px;
            }
          }
        }
      }
    }
  }
  #equipes #wrap,
  #nouveau #wrap {
    margin: 0 20px;
  }
  #nouveau {
    .truc {
      width: 50%;
      padding: 10px 20px !important;
      .contenu {
        &:before {
          opacity: 1;
        }
      }
      .int {
        opacity: 1;
        transform: none;
      }
    }
  }

  // Realisations
  #cat-grid {
    .element-item {
      width: 100%;
    }
  }

  // Realisation (single)
  #gallerie #wrap {
    #galReaSlide {
      a {
        &:after {
          background-size: 20px 20px;
        }
      }
    }
  }

  // Contact
  #introContact {
    height: auto !important;
    #maps {
      height: 360px !important;
    }
    #boxContact {
      position: relative;
      left: auto;
      bottom: auto;
      width: 100%;
      padding: 30px;
      i {
        left: 30px;
      }
      .buttons {
        margin-top: 20px;
      }
    }
  }
  #infosContact {
    height: auto;
    display: table;
    h3 {
      font-size: 20px;
    }
    #left,
    #right {
      display: table-cell;
      #int {
        font-size: 12px;
      }
    }
  }
  #formCont {
    padding-top: 0;
    #wrap {
      display: block;
      #left, #right {
        width: 100%;
      }
      #left {
        padding-bottom: 20px;
        #listServices {
          margin-left: auto;
          margin-right: auto;
          .service span {
            opacity: 1;
          }
        }
      }
    }
  }
  #formCont #wrap #right .gform_wrapper form .gform_body ul li .ginput_container input,
  #formCont #wrap #right .gform_wrapper form .gform_body ul li .ginput_container select,
  #formCont #wrap #right .gform_wrapper form .gform_body ul li .ginput_container textarea {
    padding: 8px 8px 8px 30px;
  }
}



/**
 * Mobile
 */

@media all and (max-width: 570px) {
  // Contact
  #infosContact {
    display: block;
    #left,
    #right {
      display: block;
      width: 100%;
    }
  }
  #formCont #wrap {
    padding: 40px 20px;
    #left .p {
      padding-right: 0;
    }
    #right {
      padding-left: 0;
      .gform_wrapper form .gform_body ul li {
        width: 100%;
      }
    }
  }
  #equipes #wrap {
    .equipe {
      h2 {
        img {
          height: 16px;
        }
      }
    }
  }

  // Realisations
  #realisations {
    #wrap {
      max-width: none;
      margin: 0 30px;
    }
  }

  // Realisation (single)
  #servicesListe {
    img {
      max-width: 30px;
    }
  }
  #reaInfo {
    #wrap {
      #top {
        h1, span span {
          font-size: 18px;
        }
      }
      #bottom {
        display: block;
        #first {
          max-width: none;
          padding-bottom: 20px;
        }
      }
    }
  }
}

@media all and (max-width: 475px) {
  // Home
  .scroll {
    display: none;
  }
  #intro .slick-dotted {
    width: 60px;
  }
  #intro .slick-dotted .slick-list .slick-track .slick-slide span {
    left: 30px;
  }
  #intro .slick-vertical .slick-list .slick-track .slick-slide {
    .carouBox {
      margin-right: 20px;
      left: 60px;
      bottom: 30px;
      .boxInt {
        width: auto !important;
        h2 {
          font-size: 15px;
        }
      }
    }
  }

  // Equipe
  #equipes #wrap {
    .equipe {
      h2 {
        font-size: 16px;
      }
      .listes {
        .membre {
          width: 100%;
        }
      }
    }
  }
  #nouveau {
    .truc {
      width: 100%;
      padding-left: 0 !important;
      padding-right: 0 !important;
      .contenu {
        height: 260px;
      }
    }
  }
  #introTeam,
  #introRea {
    #introInt {
      background-position: left -80px;
      #contenu {
        max-width: none;
        right: auto;
        bottom: 0;
        top: auto;
        transform: none;
        border-bottom: 1px solid #EEE;
        p {
          max-width: none;
        }
      }
    }
  }

  // Services
  #viewServices {
    .service {
      .partService {
        .contenu {
          padding: 60px 30px;
        }
        .contenuB {
          padding: 60px 30px 180px;
        }
        .bottom {
          padding-right: 30px!important;
          padding-left: 30px!important;
        }
      }
    }
  }

  // Entreprise
  #introEnt {
    min-height: 400px;
    #introBox {
      left: 0;
      bottom: 0;
    }
  }
  #a-propos #wrap {
    #list {
      .point {
        flex-direction: column-reverse !important;
        padding-bottom: 60px;
        &:nth-child(2n+2) {

        }
        .part {
          width: 100%;
          text-align: left;
          .img {
            display: none;
          }
          span {
            float: none;
            width: auto;
            height: auto;
            border: 0;
            border-radius: 0;
            line-height: 1.5em;
            margin: 0 0 20px !important;
            font-weight: 700;
            text-align: left !important;
            &:after {
              content: none;
            }
          }
        }
      }
    }
  }

  // Realisation (single)
  #introSingRea {
    min-height: 400px;
  }
}

@media all and (max-width: 424px) {
  // Global
  .corps {
    max-width: 100%;
  }
  #wrapPage header #logo,
  #wrapPage header.active #logo {
    margin-left: 20px;
    margin-top: 14px;
  }
  #wrapPage header nav,
  #wrapPage header.active nav,
  #wrapPage header.isSpecialPage nav {
    margin-right: 20px;
  }
  #wrapPage header #logo img,
  #wrapPage header.active #logo img {
    width: 110px !important;
    height: 31px !important;
  }
  .toggle-menu {
    width: 100px;
  }
  footer #top {
    padding: 40px 20px;
  }
  footer #bottom {
    padding: 20px;
    text-align: center;
  }
  footer #top .corps .partFooter#infos {
    width: 100%;
    span {
      margin-top: 10px !important;
    }
  }
  footer #top .corps #logoTop {
    margin-bottom: 20px;
  }
  footer #top .corps .partFooter#links, 
  footer #top .corps .partFooter#miniSiteMap {
    padding-top: 80px;
    width: 50%;
    ul {
      display: block;
      text-align: left;
    }
  }
  footer #top .corps .partFooter#miniSiteMap ul#social {
    top: 20px;
    left: 0;
    margin: 0;
    text-align: left;
    transform: none;
    li+li {
      margin-left: 15px;
    }
  }
  footer #top .corps .partFooter#form .gform_wrapper form .gform_body ul li .ginput_container input,
  footer #top .corps .partFooter#form .gform_wrapper form .gform_body ul li .ginput_container textarea {
    padding-left: 10px;
    padding-right: 10px;
  }

  // Home
  #services {
    .corps {
      padding: 0 0 40px;
    }
  }

  // Static
  .static {
    padding: 69px 20px 80px;
    h1 { font-size: 26px; }
    h2 { font-size: 22px; }
    h3 { font-size: 18px; }
    h4, h5, h6 { font-size: 16px; }
  }

  // Realisation (single)
  #moreRea #top {
    padding: 0 20px;
    #wrap h1 {
      font-size: 16px;
    }
  }
  #gallerie #wrap {
    #galReaSlide {
      a {
        width: 100%;
        height: 200px;
        margin: 0 0 20px !important;
      }
    }
  }
}