textarea {
  resize: none;
}
.static {
  padding: 40px 0 80px;
  h1 {
    padding: 0 0 20px;
  }
  h2 {
    padding: 30px 0 15px;
  }
  h3, h4 {
    padding: 15px 0 10px;
  }

  p {
    margin: 13px 0;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 15px 0;
  }

  ul {
    padding-left: 20px;
    list-style: disc inside;
  }
}