#introContact{
	height: 100vh;
	#maps{
		height: 100%;
	}
	#boxContact{
		background: rgba(60, 60, 59, .82);
		position: absolute;
		left: 25.29989094874591vh;
		bottom: 20.50163576881134vh;
		z-index: 3;
		padding: 4.252998909487459vh 0 3.489640130861505vh 10.25081788440567vh;
		width: 61.83206106870229vh;
		min-height: 38.276990185387135vh;
		i{
			color: $red;
			font-size: 3.271537622682661vh;
			left: 14.176663031624864vh;
			width: 2.6172300981461287vh;
			padding: 0;
			position: absolute;
		}
		h2{
			color: #fff;
			font-size: 3.271537622682661vh;
			font-family: $mont;
			font-weight: 700;
			line-height: 100%;
			text-transform: uppercase;
			float: left;
			width: 100%;
			max-width: 55.28898582333697vh;
			margin: 0 0 0 4.362050163576881vh;
			padding: 0 0 0 3.816793893129771vh;
			&:after{
				background: $red;
				display: block;
				content: "";
				width: 5.561613958560524vh;
				height: 0.4362050163576881vh;
				margin: 3.162486368593239vh 0 3.162486368593239vh -5.779716466739368vh;
			}
		}
		.span{
			position: relative;
			display: block;
			color: #fff;
			font-size: 2vh;
			font-family: $mont;
			font-weight: 300;
			line-height: 2.6172300981461287vh;
			padding: 0 0 0 3.271537622682661vh;
			clear: both;
			span{
				display: block;
				font-weight: 700;
			}
			svg{
				position: absolute;
				left: 0;
				top: 50%;
				transform: translate(0, -50%);
				width: 1.8538713195201746vh;
				height: auto;
			}
			& + .span{
				margin: 1.6357688113413305vh 0 0 0;
			}
			&.first{
				svg{
					transform: initial;
					top: 0;
				}
			}
		}
		.buttons{
			display: flex;
			margin: 2.1810250817884405vh 0 0 3.271537622682661vh;
			a{
				position: relative;
				color: #fff;
				font-size: 1.3086150490730644vh;
				font-family: $mont;
				font-weight: 700;
				letter-spacing: 0.2em;
				line-height: 100%;
				text-transform: uppercase;
				border: 0.3271537622682661vh solid $red;
				padding: 1.6357688113413305vh 3.271537622682661vh;
				display: table;
				transition-property: color;
				transition-duration: 0.4s;
				transition-delay: 0.4s;
				& + a{
					margin: 0;
				}
				span{
					position: relative;
					z-index:2;
				}

				&:after{
					background: $red;
					position: absolute;
					display: block;
					content: "";
					width: 101%;
					height: 101%;
					left: 50%;
					bottom: 0;
					opacity: 1;
					transform: translate(-50%, 0);
					transition-property: width, height, opacity;
					transition-duration: 0.4s, 0.4s, 0.4s;
					transition-delay: 0s, 0.4s, 0s;
					z-index: 0;
				}
				&:hover{
					color: $red;
					&:after{
						width: 0;
						height: 0.25vw;
						opacity: 0;
					}
				}
			}
		}
	}
}

#formCont{
	padding: 3.375vw 0 0;
	#wrap{
		background: #f5f5f5;
		display: flex;
		justify-content: space-between;
		padding: 7.3125vw 5.3125vw 5.6875vw 6.5625vw;
		max-width: 85.3125vw;
		margin: 0 auto;
		#left{
			width: 40%;
			#top{
				max-width: 26.1875vw;
				h1{
					color: $red;
					font-size: 1.875vw;
					font-family: $mont;
					font-weight: 300;
					padding: 0 3.125vw 0 0;
				}
				p{
					color: #a4a4a4;
					font-size: 0.875vw;
					font-family: $robo;
					font-weight: 400;
					padding: 0 2.5vw 0 0;
					margin: 3.125vw 0 5.5625vw 0;
				}
			}
			.p{
				color: #a4a4a4;
				font-size: 0.875vw;
				font-family: $robo;
				font-weight: 400;
				padding: 0 7.8125vw 0 0;
			}
			#listServices{
				display: flex;
				flex-wrap: wrap;
				margin: 1.875vw 0 0 0;
				max-width: 24.375vw;
				.service{
					width: 33.3333333333%;
					cursor: pointer;
					position: relative;
					span{
						display: block;
						opacity: 0;
						color: $red;
						text-align: center;
						transition-property: opacity;
						transition-duration: 0.4s;
						font-size: 0.5625vw;
						font-family: $robo;
						font-weight: 400;
						line-height: initial;
					}
					img{
						display: table;
						margin: 0.625vw auto;
						height: 30px;
					}
					&:hover{
						span{
							opacity: 1;
						}
					}
					.hidemy2par4{
						opacity: 0 !important;
					}
					&:nth-child(3n + 1){
						span{
							
						}
						img{
							
						}
					}
					&:nth-child(3n + 2){
						&:after{
							background: #d3d3d3;
							position: absolute;
							display: block;
							content: "";
							top: 50%;
							right: 0;
							width: 1px;
							height: 0.6875vw;
							transform: translate(0, -50%);
						}
						&:before{
							background: #d3d3d3;
							position: absolute;
							display: block;
							content: "";
							top: 50%;
							left: 0;
							width: 1px;
							height: 0.6875vw;
							transform: translate(0, -50%);
						}
					}
					&:nth-child(3n + 3){
						span{
							
						}
						img{
							
						}
					}
				}
			}
		}
		#right{
			width: 60%;

			.gform_confirmation_message{
				color: #fff;
				font-family: $mont;
				font-weight: 400;
				font-size: 0.875vw;
			}
			.gform_wrapper{
				form{
					.validation_error{
						display: none;
					}
					.gform_body{

						ul{
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							li{
								width: calc(50% - 0.9375vw);
								margin: 1.875vw 0 0 0;
								& + li{
									
								}
								label{
									display: none;
								}
								.validation_message{
									display: none;
								}
								.ginput_container{

									input,
									textarea,
									select{
										appearance: none;
										background-color: transparent;
										color: #787878;
										width: 100%;
										height: auto;
										border: none;
										border-bottom: 1px solid #aeaeae;
										border-radius: 0;
										font-family: $robo;
										font-weight: 400;
										font-size: 0.9375vw;
										padding: 0.9375vw 3.25vw;
										::-webkit-input-placeholder { /* Chrome/Opera/Safari */
											color: #787878;
										}
										::-moz-placeholder { /* Firefox 19+ */
											color: #787878;
										}
										:-ms-input-placeholder { /* IE 10+ */
											color: #787878;
										}
										:-moz-placeholder { /* Firefox 18- */
											color: #787878;
										}
									}
									textarea{
										height: 10.75vw;
										padding: 1.25vw 3.25vw;

									}
								}
								&.gfield_error{
									.ginput_container{

										input,
										textarea,
										select{
											border-bottom: 0.1875vw solid $red;

										}
									}
								}
								&.giveIcon{
									.ginput_container{

										input,
										textarea,
										select{
											background-image: url(../images/jobs.png);
											background-size: 1.25vw auto;
											background-position: 0.9375vw center;
											background-repeat: no-repeat;
										}
									}
								}
								&.userIcon{
									.ginput_container{

										input,
										textarea,
										select{
											background-image: url(../images/guest.png);
											background-size: 1.25vw auto;
											background-position: 0.9375vw center;
											background-repeat: no-repeat;
										}
									}
								}
								&.mailIcon{
									.ginput_container{

										input,
										textarea,
										select{
											background-image: url(../images/at.png);
											background-size: 1.25vw auto;
											background-position: 0.9375vw center;
											background-repeat: no-repeat;
										}
									}
								}
								&.targetIcon{
									.ginput_container{

										input,
										textarea,
										select{
											background-image: url(../images/target.png);
											background-size: 1.25vw auto;
											background-position: 0.9375vw center;
											background-repeat: no-repeat;
										}
									}
								}
								&.phoneIcon{
									.ginput_container{

										input,
										textarea,
										select{
											background-image: url(../images/phone.png);
											background-size: 1.25vw auto;
											background-position: 0.9375vw center;
											background-repeat: no-repeat;
										}
									}
								}
								&.noteIcon{
									.ginput_container{

										input,
										textarea,
										select{
											background-image: url(../images/note.png);
											background-size: 1.25vw auto;
											background-position: 0.9375vw center;
											background-repeat: no-repeat;
										}
									}
								}
								&:last-child{
									width: 100%;
									.ginput_container{

										input,
										textarea,
										select{
											// background-image: url(../images/jobs.png);
											// background-size: 1.25vw auto;
											background-position: 0.9375vw 1.25vw;
											background-repeat: no-repeat;
											height: 15.3125vw;
										}
									}
								}
							}
						}
					}
					.gform_footer{
						margin: 3.75vw 0 0 0;
						&:after{
							display: table;
							content: "";
							clear: both;
						}
						input{
							background: transparent;
							border: 0.1875vw solid $red;
							color: $red;
							font-family: $robo;
							font-weight: 900;
							font-size: 0.75vw;
							letter-spacing: 0.2em;
							text-transform: uppercase;
							padding: 1.25vw 3.125vw;
							margin: 0;
							float: initial;
							transition-duration: 0.4s, 0.4s;
							transition-property: color, border-color;
							&:hover{
								color: #323232;
								border-color: #323232;
							}
						}
					}
				}
			}
		}
	}
}


#infosContact{
	display: flex;
	font-family: $mont;
	font-size: 16px;
	justify-content: space-between;
	width: 100%;
	height: 800px;
	h3{
		padding: 15px 0 20px;
		font-family: $mont;
		font-weight: 700;
		line-height: 100%;
		font-size: 23px;
	}
	p {
		margin: 13px 0;
	}
	strong {
		font-weight: 700;
	}
	#left,
	#right{
		position: relative;
		display: flex;
		align-content: center;
		align-items: top;
		height: 100%;
		#int{
			width: 600px;
		}
	}
	#left{
		background: $red;
		width: 50%;
		color: #FFF;
		#int{
			margin: auto;
			padding: 60px 130px 60px 70px;
		}
	}
	#right{
		background: #aeaeae;
		width: 50%;
		color: #FFF;
		#int{
			margin: auto;
			padding: 60px 70px 60px 130px;
			h3{
				color: $red;
			}
			strong {
				color: #3c3c3b;
			}
		}
	}
	#tva {
		font-size: 10px;
	}
	#social {
		margin: 15px 0;
		li {
			margin: 10px 15px;
			display: inline-block;
			&:first-child {
				margin-left: 0;
			}
		}
		a {
			font-size: 20px;
			color: #FFF;
			&:hover,
			&:active {
				color: #3c3c3b;
			}
		}
	}
}

.infosContactDetails {
	display: block;
	padding: 8px 0;
	span {
		display: block;
		padding: 8px 0;
	}
}