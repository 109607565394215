#servicesListe {
	margin-bottom: 25px;
	img {
		display: inline-block;
		max-width: 40px;
		height: auto;
		vertical-align: bottom;
		margin: 5px 10px;
		&:first-child {
			margin-left: 0;
		}
	}
}

#introSingRea{
	height: 100vh;
	min-height: 555px;
	#wrap{
		height: 100%;
		.slick-slider{
			height: 100%;
			.slick-list{
				height: 100%;
				.slick-track{
					height: 100%;
					.slick-slide{
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center bottom;
						height: 100%;
					}
				}
			}
		}
	}
}

#reaInfo{
	background: #f5f5f5;
	padding: 7.5vw 0 21.875vw;
	#wrap{
		max-width: 71.6875vw;
		margin: 0 auto;
		#top{
			h1{
				color: $red;
				font-size: 1.875vw;
				font-family: $mont;
				font-weight: 400;
				letter-spacing: 0.05em;
				text-transform: uppercase;
			}
			span{
				color: $red;
				font-size: 1.125vw;
				font-family: $mont;
				font-weight: 400;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				span{
					font-size: 1.875vw;
					text-transform: uppercase;
				}
			}
			&:after{
				background: $red;
				display: block;
				content: "";
				width: 3.3125vw;
				height: 5px;
				margin: 1.9375vw 0 2.0625vw -2.375vw;
			}
		}
		#bottom{
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			#first{
				max-width: 45.6875vw;

				p{
					color: #000;
					font-size: 0.875vw;
					font-family: $robo;
					font-weight: 300;
					line-height: 1.5vw;
				}
			}
			div{
				a{
					position: relative;
					color: $red;
					font-size: 0.75vw;
					font-family: $mont;
					font-weight: 900;
					letter-spacing: 0.2em;
					line-height: initial;
					text-transform: uppercase;
					padding: 0.9375vw 1.125vw;
					border: 3px solid $red;
					transition-property: color;
					transition-duration: 0.4s;
					transition-delay: 0.4s;
					span{
						position: relative;
						z-index: 2;
					}
					&:after{
						background: $red;
						position: absolute;
						display: block;
						content: "";
						width: 0;
						height: 0.25vw;
						left: 50%;
						bottom: 0;
						opacity: 0;
						transform: translate(-50%, 0);
						transition-property: width, height, opacity;
						transition-duration: 0.4s, 0.4s, 0.4s;
						transition-delay: 0s, 0.4s, 0s;
						z-index: 0;
					}
					&:hover{
						color: #fff;
						&:after{
							width: 101%;
							height: 101%;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

#gallerie{
	padding: 0 0 12.1875vw;
	#wrap{
		max-width: 71.6875vw;
		margin: 0 auto;
		#galReaSlide{
			margin: -13.3125vw 0 0 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			a{
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				position: relative;
				display: block;
				width: calc(50% - 1.1875vw);
				height: 26.25vw;
				overflow: hidden;
				&:nth-child(1n + 3){
					margin: 2.4375vw 0 0 0;
				}

				&:after{
					background-color: rgba(226, 8, 22, 0.91);
					background-image: url(../images/loupe.png);
					background-size: 3.0625vw 3.1875vw;
					background-position: center center;
					background-repeat: no-repeat;
					position: absolute;
					display: block;
					content: "";
					top: 50%;
					left: 50%;
					width: 0;
					height: 0;
					opacity: 0;
					transform: translate(-50%, -50%);
					transition-property: width, height, opacity;
					transition-duration: 0.4s, 0.4s, 0.4s;
				}
				&:hover{
					&:after{
						width: 100%;
						height: 100%;
						opacity: 1;
					}
				}

				div {
					width:100%; height:100%; background-size:cover; background-position:center;
				}
			}
		}
	}
}

#moreRea{
	background: #f5f5f5;
	padding: 6.5625vw 0 7.1875vw;

	#top{
		#wrap{
			max-width: 61.25vw;
			margin: 0 auto;
			h1{
				color: $red;
				font-size: 1.875vw;
				font-family: $mont;
				font-weight: 400;
				text-align: center;
				text-transform: uppercase;
				letter-spacing: 0.05em;
				line-height: initial;
				margin: 0 0 2.375vw;
			}
			p{
				margin: 0 0 3.875vw;
				color: #8d8d8d;
				font-size: 0.875vw;
				line-height: 1.5vw;
				font-family: $robo;
				font-weight: 300;
			}
		}
	}
	#bottom{
		.slick-slider{
			.slick-arrow{
				width: 3.9375vw;
				height: 4.1875vw;
				z-index: 3;
				border-top: 3px solid $red;
				border-bottom: 3px solid $red;
				&:before{
					background-image: url(../images/arrow-more.png);
					background-size: 100% 100%;
					background-position: center center;
					position: absolute;
					display: block;
					content: "";
					width: 0.6875vw;
					height: 1.1875vw;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				
				&.slick-prev{
					left: 0;
					border-right: 3px solid $red;
				}
				&.slick-next{
					right: 0;
					border-left: 3px solid $red;
					&:before{
						transform: translate(-50%, -50%) rotate(180deg);
					}
				}
			}
			.slick-arrow:hover{
				background-color:#e30613;

				&:before{
					background-image: url(../images/arrow-more-white.png);
				}
			}

			.slick-arrow:hover{
			
			}
			.slick-list{
				max-width: 61.25vw;
				margin: 0 auto;
				.slick-track{
					
					.slick-slide{
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center bottom;
						position: relative;
						height: 14.125vw;
						margin: 0 7.5px;
						overflow: hidden;
						.hover{
							background-color: rgba(226, 8, 22, 0.91);
							background-image: url(../images/loupe.png);
							background-size: 1.75vw 1.8125vw;
							background-position: center center;
							background-repeat: no-repeat;
							position: absolute;
							display: flex;
							align-content: flex-end;
							align-items: flex-end;
							flex-wrap: wrap;
							content: "";
							top: 50%;
							left: 50%;
							width: 0;
							height: 0;
							opacity: 0;
							padding: 1.6875vw 1.1875vw;
							transform: translate(-50%, -50%);
							transition-property: width, height, opacity;
							transition-duration: 0.4s, 0.4s, 0.4s;
							h5{
								color: #fff;
								font-size: 0.875vw;
								font-family: $mont;
								font-weight: 400;
								letter-spacing: 0.05em;
								text-transform: uppercase;
								margin: 0 0 0.5vw 0;
								display: block;
								width: 100%;

							}
							span{
								display: block;
								width: 100%;
								color: #fff;
								font-size: 0.625vw;
								font-family: $mont;
								font-weight: 400;
								letter-spacing: 0.05em;
								span{
									display: initial;
									font-size: 0.875vw;
									text-transform: uppercase;
								}
							}
						}
						&:hover{
							.hover{
								width: 100%;
								height: 100%;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}



#introSingRea{
	.slick-vertical{
		height: 100% !important;
		.slick-list{
			height: 100% !important;

			.slick-track{
				height: 100% !important;
				.slick-slide{
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center center;
					position: relative;
					opacity: 0;
					height: 100% !important;
					min-height: 700px;
					transition-property: opacity;
					transition-duration: 0.4s;
					&:before{
						background-color: rgba(0, 0, 0, 0.1);
						position: absolute;
						display: block;
						content: "";
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;

					}
					&.slick-current{
						opacity: 1;
					}
					.carouBox{
						background-color: rgba(60, 60, 59, .71);
						position: absolute;
						left: 25.081788440567067vh;
						bottom: 21.37404580152672vh;
						.boxInt{
							width: 76.77208287895311vh;
							padding: 3.816793893129771vh 0 2.7262813522355507vh 11.34133042529989vh;
							i{
								color: $red;
								font-size: 3.271537622682661vh;
								float: left;
								width: 2.6172300981461287vh;
								padding: 0 0 0 2.1810250817884405vh;
							}
							h2{
								color: #fff;
								font-size: 3.271537622682661vh;
								font-family: $mont;
								font-weight: 700;
								line-height: 100%;
								text-transform: uppercase;
								float: left;
								width: calc(100% - 2.6172300981461287vh);
								padding: 0 0 0 3.489640130861505vh;
								&:after{
									background: $red;
									display: block;
									content: "";
									width: 5.561613958560524vh;
									height: 0.4362050163576881vh;
									margin: 3.271537622682661vh 0 2.1810250817884405vh -5.997818974918212vh;
								}
							}
							p{
								color: #fff;
								font-size: 0.875vw;
								font-family: $mont;
								font-weight: 300;
								line-height: 2.6172300981461287vh;
								padding: 0 0 0 2.1810250817884405vh;
								max-width: 56.706652126499456vh;
								clear: both;
							}
							.buttons{
								display: flex;
								margin: 2.1810250817884405vh 0 0 0;
								a{
									position: relative;
									color: #fff;
									font-size: 1.3086150490730644vh;
									font-family: $mont;
									font-weight: 700;
									letter-spacing: 0.2em;
									line-height: 100%;
									text-transform: uppercase;
									border: 0.3271537622682661vh solid #fff;
									padding: 1.6357688113413305vh 1.0905125408942202vh;
									display: table;
									transition-property: color;
									transition-duration: 0.4s;
									transition-delay: 0.4s;
									& + a{
										margin: 0 0 0 2.1810250817884405vh;
									}
									span{
										position: relative;
										z-index:2;
									}

									&:after{
										background: #fff;
										position: absolute;
										display: block;
										content: "";
										width: 0;
										height: 0.25vw;
										left: 50%;
										bottom: 0;
										opacity: 0;
										transform: translate(-50%, 0);
										transition-property: width, height, opacity;
										transition-duration: 0.4s, 0.4s, 0.4s;
										transition-delay: 0s, 0.4s, 0s;
										z-index: 0;
									}
									&:hover{
										color: $red;
										&:after{
											width: 101%;
											height: 101%;
											opacity: 1;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.slick-dotted{
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		width: 25.081788440567067vh;
		height: 100%;
		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
				.slick-slide{
					height: 100%;
					position: relative;
					span{
						position: absolute;
						display: table;
						top: 42.42093784078517vh;
						left: 50%;
						color: $red;
						font-size: 5.670665212649945vh;
						font-family: $mont;
						font-weight: 700;
						line-height: 100%;
						writing-mode: vertical-lr;
  						text-orientation: sideways;
  						transform: translate(-50%, 0);
						&:before{
							background: $red;
							position: absolute;
							display: table;
							content: "";
							width: 0.4362050163576881vh;
							height: 5.561613958560524vh;
							margin: 0 auto;
							top: -7.52453653217012vh;
							left: 50%;
							transform: translate(-50%, 0);
						}
					}
				}
			}
		}
		.slick-dots{
			bottom: 8.7546346782988vh;
			text-align: initial;
			display: table;
			width: auto;
			left: 50%;
			transform: translate(-50%, 0);
			li{
				background: #fff;
				display: block;
				width: 1.0905125408942202vh;
				height: 1.0905125408942202vh;
				border-radius: 1.0905125408942202vh;
				margin: 0;
				& + li{
					margin: 10px 0 0 0;
				}
				button{
					width: 1.0905125408942202vh;
					height: 1.0905125408942202vh;
					&:before {
						content: none;
					}
				}
				&.slick-active{
					background: $red;
					width: 1.0905125408942202vh;
					height: 1.0905125408942202vh; // 10px for 917 screen height
				}
			}
		}
	}
}