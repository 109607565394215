#introTeam{
	height: 100vh;
	#introInt{
		background-repeat: no-repeat;
		background-position: left bottom;
		background-size: cover;
		position: absolute;
		width: 100%;
		height: calc(100vh - 8.375vh);

		#contenu{
			position: absolute;
			top: 50%;
			right: 24.318429661941114vh;
			width: 100%;
			max-width: 55.28898582333697vh;
			transform: translate(0, -50%);

			i{
				position: absolute;
				color: $red;
				left: 0;
				font-size: 3.271537622682661vh;
				width: 2.6172300981461287vh;
				padding: 0;
			}
			h2{
				color: $red;
				font-size: 3.271537622682661vh;
				font-family: $mont;
				font-weight: 700;
				line-height: 100%;
				text-transform: uppercase;
				float: left;
				width: 100%;
				max-width: 55.28898582333697vh;
				margin: 0 auto;
				padding: 0 0 0 3.816793893129771vh;
				&:after{
					background: $red;
					display: block;
					content: "";
					width: 5.561613958560524vh;
					height: 0.4362050163576881vh;
					margin: 3.271537622682661vh 0 2.1810250817884405vh -3.4375vw;
				}
			}
			p{
				color: #3c3c3b;
				font-size: 1.5267175572519085vh;
				font-family: $robo;
				font-weight: 400;
				line-height: 2.6172300981461287vh;
				padding: 0;
				max-width: 55.28898582333697vh;
				clear: both;
			}
			.buttons{
				display: flex;
				margin: 2.1810250817884405vh 0 0;
				a{
					position: relative;
					color: $red;
					font-size: 1.3086150490730644vh;
					font-family: $mont;
					font-weight: 700;
					letter-spacing: 0.2em;
					line-height: 100%;
					text-transform: uppercase;
					border: 0.3271537622682661vh solid $red;
					padding: 1.6357688113413305vh 2.0905125408942202vh;
					display: table;
					transition-property: color;
					transition-duration: 0.4s;
					transition-delay: 0.4s;
					& + a{
						margin: 0 0 0 2.1810250817884405vh;
					}
					span{
						position: relative;
						z-index:2;
					}

					&:after{
						background: $red;
						position: absolute;
						display: block;
						content: "";
						width: 0;
						height: 0.25vw;
						left: 50%;
						bottom: 0;
						opacity: 0;
						transform: translate(-50%, 0);
						transition-property: width, height, opacity;
						transition-duration: 0.4s, 0.4s, 0.4s;
						transition-delay: 0s, 0.4s, 0s;
						z-index: 0;
					}
					&:hover{
						color: #fff;
						&:after{
							width: 101%;
							height: 101%;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

#equipes{
	padding: 8.125vw 0 10.75vw;
	#wrap{
		max-width: 71.75vw;
		margin: 0 auto;
		.equipe{
			h2{
				display: flex;
				color: $red;
				font-size: 2.25vw;
				font-family: $mont;
				font-weight: 300;
				margin: 0 0 3.125vw;
				text-transform: uppercase;
				align-items: center;
				line-height: 2.5vw;
				img{
					display: block;
					width: auto;
					height: 2.5vw;
				}
				span{
					padding: 0 0 0 2.5vw;
				}
			}
			& + .equipe{
				margin: 5.8125vw 0 0;
			}
			.listes{
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				
				.membre{
					background-color: rgba(240, 243, 242, 0);
					width: calc(33.3333333333% - 1.25vw);
					border-style: solid; border-width: 1px;
					border-color: rgb(223, 229, 232);
					background-color: rgba(240, 243, 242, 0);
					padding: 2.8125vw 3vw 2.9375vw;
					margin: 0 0 2.625vw;
					transition-duration: 0.4s;
					transition-property: box-shadow;
					position: relative;
					&:hover{
						background-color: rgba(240, 243, 242, 0);
						border-color: rgb(223, 229, 232);
						box-shadow: 0px 0px 0.5vw 0px rgba(0, 0, 0, 0.08);
					}
					&.notAdmin{
						background-color: rgb(240, 243, 242);
						border-style: solid; border-width: 1px;
						border-color: transparent;
						padding: 2.6875vw 2.1875vw 2.9375vw 2.375vw;
						display: flex;
						align-items: center;
						transition-duration: 0.4s;
						transition-property: background-color;
						&:hover{
							background-color: #fff;
						}
					}
					// Administrative
					.profilPictureAdmin{
						transition: all 0.25s $easeinoutquad;
						display: block;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
						width: 7.9375vw;
						height: 7.9375vw;
						border-radius: 7.9375vw;
						margin: 0 0 2.5vw 0;
						&:hover, &:active, &:focus {
							transform: scale(1.05);
						}
					}
					.contenuAdmin{
						h3{
							&:after{
								background: $red;
								display: block;
								content: "";
								width: 2.5vw;
								height: 1px;
								margin: 1.125vw 0 1.0625vw -1.4375vw
							}
						}
						span{
							display: block;
							&:nth-child(2){
								color: #99a9b5;
								font-size: 0.875vw;
								line-height: initial;
								font-family: $robo;
								font-weight: 400;
							}
							&:nth-child(4){
								color: $red;
								font-size: 1.1875vw;
								line-height: initial;
								font-family: $mont;
								font-weight: 700;
								margin: 0.625vw 0 0 0;
							}
						}
						.contactEquipe {
							float: right;
							margin: -0.625vw 0 0;
							img{
								display: block;
								width: auto;
								height: 1.5vw;
							}
						}
					}

					//not administrative
					.profilPicture{
						transition: all 0.25s $easeinoutquad;
						display: block;
						background-repeat: no-repeat;
						background-size: cover;
						background-position: center center;
						width: 4.9375vw;
						height: 4.9375vw;
						border-radius: 4.9375vw;
						&:hover, &:active, &:focus {
							transform: scale(1.05);
						}
					}
					.contenu{
						position: relative;
						padding: 0 0 0 1.0625vw;
						h4{
							color: #3c3950;
							font-size: 1.4375vw;
							font-family: $robo;
							font-weight: 300;
							margin: 0 0 0.625vw;
						}
						span{
							color: #99a9b5;
							font-size: 0.875vw;
							font-family: $robo;
							font-weight: 300;
						}						
					}

					.year-badge {
							position: absolute;
							right: 15px;
							bottom: 15px; //calc(50vh-35px);
							width: 70px;
							height: 70px;
							background-image: url("../images/experience.svg");
							background-size: cover;
							background-position: center;
							padding-top: 24px;
							text-align: center;
							line-height: 12px;
							color: #FFF;
							font-size: 1.3em;
							span {
								font-size: 0.7em;
							}

						}
				}

				.membre.mebre_end {
					opacity: 0;
				}
			}
		}
	}
}

// Great choice of ID names btw… GOCH
#nouveau {
	margin-bottom: 100px;
	#wrap {
		max-width: 71.75vw;
		margin: 0 auto;
	}
	h2 {
		font-family: $mont;
		font-weight: 300;
		font-size: 36px;
		text-transform: uppercase;
		color: $red;
		margin-bottom: 60px;
		img {
			display: inline-block;
			margin-right: 10px;
			vertical-align: top;
		}
	}
	.trucs {
		zoom: 1;
		&:before, &:after {
			content: "";
			display: table;
		}
		&:after {
			clear: both;
		}
	}
	.truc {
		float: left;
		width: 33.333%;
		padding: 10px 20px 10px;
		&:nth-child(3n) {
			padding-right: 0;
		}
		&:nth-child(3n+1) {
			padding-left: 0;
		}
		&:hover, &:active, &:focus {
			.bg {
				filter: none;
			}
			.contenu {
				&:before {
					opacity: 1;
				}
			}
			.int {
				opacity: 1;
				transform: translateY(0);
			}
		}
		.contenu {
			display: flex;
			align-items: center;
			position: relative;
			height: 600px;
			border-bottom: 10px solid $red;
			&:before {
				content: '';
				transition: all 0.25s $easeinoutquad;
				opacity: 0;
				background-color: rgba(0,0,0,.65);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 6;
			}
		}
		.bg {
			transition: all 0.25s $easeinoutquad;
			filter: grayscale(100%);
			background-color: #b6c6c9;
			background-position: center center;
			background-size: cover;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 4;
		}
		.int {
			transition: all 0.25s $easeinoutquad;
			transform: translateY(20px);
			opacity: 0;
			width: 100%;
			position: relative;
			z-index: 8;
			text-align: center;
			padding: 10px;
			img {
				display: inline-block;
				width: 100%;
				max-width: 80px;
				height: auto;
				margin-bottom: 10px;
			}
			h4 {
				font-size: 18px;
				line-height: initial;
				font-family: $mont;
				font-weight: 700;
				text-transform: uppercase;
				color: $red;
				margin: 15px 0 10px;
			}
			h5 {
				font-size: 14px;
				line-height: initial;
				font-family: $robo;
				font-weight: 300;
				color: #FFF;
			}
		}
	}
}
.contactEquipeTel {
	color: $red;
}