* {
	text-rendering: optimizeLegibility !important;
	outline: 0 !important;

	&::selection {
	  background: $red; /* WebKit/Blink Browsers */
	  color: #fff;
	}
	&::-moz-selection {
	  background: $red; /* Gecko Browsers */
	  color: #fff;
	}
	/*&:not(.slick-track),
	&:not(.slick-list){
		transition: .1s;
	}*/
}

html,
body{
	margin: 0;
	padding: 0;
}


body{
	//for IE -_-
	overflow-x: hidden;
	&.is--frozen {
		overflow: hidden;
	}
}

h1,
h2,
h3,
h4,
h5,
h6{
	margin: 0;
}

p{
	margin: 0;
}

ul{
	margin: 0;
	padding: 0;
	list-style: none;
	li{

	}
}
		
a{
	text-decoration: none !important;
}

section{
	position: relative;
	z-index: 1;
}

.clear{
	clear: both;
}

.corps{
	position: relative;
	display: block;
	max-width: $corps;
	margin: 0 auto;
}


.scroll{
	background-image: url(../images/scroll.png);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	position: absolute;
	display: table;
	width: 24px;
	height: 86px;
	left: 50%;
	bottom: 4.252998909487459vh;
	transform: translate(-50%, 0);
	z-index: 8;
	cursor: pointer;
}




// Loader
/*#loader{
	background: #000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 1;
	transition-property: opacity;
	transition-duration: 0.4s;
	transition-delay: 1.5s;
	&.active{
		opacity: 0;
	}
	img{
		position: absolute;
		top: 50%;
		left: 50%;
		width: 300px;
		height: auto;
		transform: translate(-50%, -50%);
	}
}
#wrapPage{
	opacity: 0;
	transition-property: opacity;
	transition-duration: 0.4s;
	transition-delay: 1.5s;
	&.active{
		opacity: 1;
	}
}*/