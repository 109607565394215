header{
	background: #3c3c3b;
	position: fixed;
	display: block;
	width: 100%;
	height: 8.375vw;
	top: 0;
	left: 0;
	z-index: 10;
	transition-property: height;
	transition-duration: 0.4s;
	#logo,
	nav{
		position: absolute;
		top: 50%;
		transform: translate(0, -50%);
	}
	#logo{
		left: 14.125vw;
		img{
			display: block;
			width: 223px;
			height: 63px;
			transition-property: width;
			transition-duration: 0.4s;
		}
	}
	nav{
		right: 5vw;
		height: 100%;
		height: 100%;
		ul{
			display: flex;
			justify-content: space-between;
			height: 100%;
			float: left;
			&#headMenu{
				width: 49.5vw;
				right: 9.5vw;
				transition-property: width;
				transition-duration: 0.4s;
				li{
					position: relative;
					display: flex;
					height: 100%;
					border-top: 0.25vw solid transparent;
					& + li{
						
					}
					a{
						display: block;
						left: 0;
						width: 100%;
						color: #fff;
						font-size: 14px !important;
						font-family: $mont;
						font-weight: 300;
						transition-property: color;
						transition-duration: 0.4s;
						letter-spacing: 0.1em;
						text-align: center;
						align-self: center;
						transition-property: font-size;
						transition-duration: 0.4s;
						&:hover{
							color: $red;
							font-weight: 300;
						}
					}

					&.current-menu-item,
					&.current-menu-parent{
						border-top-color: $red;
						a{
							color: $red;
							font-size: 14px;
							font-weight: 700;
							transition-property: font-size;
							transition-duration: 0.4s;
						}
						ul{
							background: $red;
							position: absolute;
							display: none !important;
							justify-content: initial;
							height: auto;
							float: initial;
							left: -3.4375vw;
							min-width: 22.0625vw;
							top: 100%;
							padding: 1.25vw 0;
							&:after{
								background: #c2010c;
								position: absolute;
								display: block;
								content: "";
								top: 50%;
								left: 50%;
								width: 2px;
								height: calc(100% - 40px);
								transform: translate(-50%, -50%);
							}
							&:before{
								background: $red;
								position: absolute;
								display: block;
								content: "";
								width: 30px;
								height: 30px;
								transform: rotate(45deg);
								top: -1vw;
								left: 5vw;
								z-index: -1;
							}
							li{
								display: block;
								width: 50%;
								a{
									color: #fff;
									text-align: left;
									font-size: 0.625vw;
									font-family: $mont;
									font-weight: 300;
									letter-spacing: 0.2em;
									text-transform: uppercase;
									transition-property: background;
									transition-duration: 0.4s;
									padding: 0.9375vw 0 0.9375vw 2.5vw;
									&:hover{
										background: #c2010c;
										font-weight: 300;
										color: #fff;
									}
								}
								&:after{
									background-image: url(../images/arrow-right.svg);
									background-size: 100% 100%;
									background-repeat: no-repeat;
									background-position: center center;
									position: absolute;
									display: block;
									content: "";
									width: 0.4375vw;
									height: 0.4375vw;
									top: 50%;
									right: 1.125vw;
									transform: translate(0, -50%);
								}
							}
						}
					}
					ul{
						background: $red;
						position: absolute;
						display: none !important;
						justify-content: initial;
						height: auto;
						float: initial;
						left: -3.4375vw;
						min-width: 22.0625vw;
						top: 100%;
						padding: 1.25vw 0;
						&:after{
							background: #c2010c;
							position: absolute;
							display: block;
							content: "";
							top: 50%;
							left: 50%;
							width: 2px;
							height: calc(100% - 2.5vw);
							transform: translate(-50%, -50%);
						}
						&:before{
							background: $red;
							position: absolute;
							display: block;
							content: "";
							width: 3.125vw;
							height: 3.125vw;
							transform: rotate(45deg);
							top: -1.25vw;
							left: 3.75vw;
							z-index: -1;
						}
						li{
							display: block;
							width: 50%;
							a{
								text-align: left;
								font-size: 9px !important;
								font-family: $mont;
								font-weight: 300;
								letter-spacing: 0.2em;
								text-transform: uppercase;
								transition-property: background;
								transition-duration: 0.4s;
								padding: 0.9375vw 0 0.9375vw 2.5vw;
								&:hover{
									background: #c2010c;
									font-weight: 300;
									color: #fff;
								}
							}
							&:after{
								background-image: url(../images/arrow-right.svg);
								background-size: 100% 100%;
								background-repeat: no-repeat;
								background-position: center center;
								position: absolute;
								display: block;
								content: "";
								width: 0.4375vw;
								height: 0.4375vw;
								top: 50%;
								right: 1.125vw;
								transform: translate(0, -50%);
							}

							&.current-menu-item{
								a{
									background: #c2010c;
									color: #fff;
									text-align: left;
									font-size: 0.625vw;
									font-family: $mont;
									font-weight: 300;
									letter-spacing: 0.2em;
									text-transform: uppercase;
									transition-property: background;
									transition-duration: 0.4s;
									padding: 0.9375vw 0 0.9375vw 2.5vw;
									&:hover{
										background: #c2010c;
										font-weight: 300;
										color: #fff;
									}
								}
								&:after{
									background-image: url(../images/arrow-right.svg);
									background-size: 100% 100%;
									background-repeat: no-repeat;
									background-position: center center;
									position: absolute;
									display: block;
									content: "";
									width: 0.4375vw;
									height: 0.4375vw;
									top: 50%;
									right: 1.125vw;
									transform: translate(0, -50%);
								}
							}
						}
					}
					&:hover{
						ul{
							display: flex !important;
							flex-wrap: wrap;

							li{

								a{

								}
							}
						}
					}
				}

				#menu-item-1760 {
					a {
						font-size:0 !important;
						&:before {
							font-family: FontAwesome; 
						    font-size: 20px; 
						    display: inline-block; 
						    content: '\f015';
						};
					}
					
				}
			}
			&#social{
				right: 0;
				margin: 0 0 0 6.5vw;
				li{
					display: flex;
					align-self: center;
					border-top: 0.25vw solid transparent;
					& + li{
						padding: 0 0 0 14px;
					}
					a{
						color: #1f1d1f;
						font-size: 14px;
						transition-property: color;
						transition-duration: 0.4s;
						letter-spacing: 0.1em;
						&:hover{
							color: $red;
						}
					}
				}
			}
		}
	}
	&.active{
		background: #3c3c3b;
		position: fixed;
		display: block;
		width: 100%;
		height: 5.375vw;
		z-index: 10;
		#logo,
		nav{
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
		}
		#logo{
			left: 14.125vw;
			img {
				width: 130px;
			}
		}
		nav{
			right: 5vw;
			height: 100%;
			height: 100%;
			ul{
				display: flex;
				justify-content: space-between;
				height: 100%;
				float: left;
				&#headMenu{
					width: 50vw;
					right: 9.5vw;
					li{
						position: relative;
						display: flex;
						height: 100%;
						border-top: 0.25vw solid transparent;
						& + li{
							
						}
						a{
							display: block;
							left: 0;
							width: 100%;
							color: #fff;
							font-size: 12px;
							font-family: $mont;
							font-weight: 300;
							transition-property: color;
							transition-duration: 0.4s;
							letter-spacing: 0.1em;
							text-align: center;
							align-self: center;
							&:hover{
								color: $red;
								font-weight: 300;
							}
						}

						&.current-menu-item,
						&.current-menu-parent{
							border-top-color: $red;
							a{
								color: $red;
								font-size: 12px;
								font-weight: 700;
							}
							ul{
								background: $red;
								position: absolute;
								display: none !important;
								justify-content: initial;
								height: auto;
								float: initial;
								left: -3.4375vw;
								min-width: 22.0625vw;
								top: 100%;
								padding: 1.25vw 0;
								&:after{
									background: #c2010c;
									position: absolute;
									display: block;
									content: "";
									top: 50%;
									left: 50%;
									width: 2px;
									height: calc(100% - 40px);
									transform: translate(-50%, -50%);
								}
								&:before{
									background: $red;
									position: absolute;
									display: block;
									content: "";
									width: 30px;
									height: 30px;
									transform: rotate(45deg);
									top: -1vw;
									left: 5vw;
									z-index: -1;
								}
								li{
									display: block;
									width: 50%;
									a{
										color: #fff;
										text-align: left;
										font-size: 0.600vw;
										font-family: $mont;
										font-weight: 300;
										letter-spacing: 0.2em;
										text-transform: uppercase;
										transition-property: background;
										transition-duration: 0.4s;
										padding: 0.9375vw 0 0.9375vw 2.5vw;
										&:hover{
											background: #c2010c;
											font-weight: 300;
											color: #fff;
										}
									}
									&:after{
										background-image: url(../images/arrow-right.svg);
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-position: center center;
										position: absolute;
										display: block;
										content: "";
										width: 0.4375vw;
										height: 0.4375vw;
										top: 50%;
										right: 1.125vw;
										transform: translate(0, -50%);
									}
								}
							}
						}
						ul{
							background: $red;
							position: absolute;
							display: none !important;
							justify-content: initial;
							height: auto;
							float: initial;
							left: -3.4375vw;
							min-width: 22.0625vw;
							top: 100%;
							padding: 1.25vw 0;
							&:after{
								background: #c2010c;
								position: absolute;
								display: block;
								content: "";
								top: 50%;
								left: 50%;
								width: 2px;
								height: calc(100% - 2.5vw);
								transform: translate(-50%, -50%);
							}
							&:before{
								background: $red;
								position: absolute;
								display: block;
								content: "";
								width: 3.125vw;
								height: 3.125vw;
								transform: rotate(45deg);
								top: -1.25vw;
								left: 3.75vw;
								z-index: -1;
							}
							li{
								display: block;
								width: 50%;
								a{
									text-align: left;
									font-size: 0.625vw;
									font-family: $mont;
									font-weight: 300;
									letter-spacing: 0.2em;
									text-transform: uppercase;
									transition-property: background;
									transition-duration: 0.4s;
									padding: 0.9375vw 0 0.9375vw 2.5vw;
									&:hover{
										background: #c2010c;
										font-weight: 300;
										color: #fff;
									}
								}
								&:after{
									background-image: url(../images/arrow-right.svg);
									background-size: 100% 100%;
									background-repeat: no-repeat;
									background-position: center center;
									position: absolute;
									display: block;
									content: "";
									width: 0.4375vw;
									height: 0.4375vw;
									top: 50%;
									right: 1.125vw;
									transform: translate(0, -50%);
								}

								&.current-menu-item{
									a{
										background: #c2010c;
										color: #fff;
										text-align: left;
										font-size: 0.625vw;
										font-family: $mont;
										font-weight: 300;
										letter-spacing: 0.2em;
										text-transform: uppercase;
										transition-property: background;
										transition-duration: 0.4s;
										padding: 0.9375vw 0 0.9375vw 2.5vw;
										&:hover{
											background: #c2010c;
											font-weight: 300;
											color: #fff;
										}
									}
									&:after{
										background-image: url(../images/arrow-right.svg);
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-position: center center;
										position: absolute;
										display: block;
										content: "";
										width: 0.4375vw;
										height: 0.4375vw;
										top: 50%;
										right: 1.125vw;
										transform: translate(0, -50%);
									}
								}
							}
						}
						&:hover{
							ul{
								display: flex !important;
								flex-wrap: wrap;

								li{

									a{

									}
								}
							}
						}
					}
				}
				&#social{
					right: 0;
					margin: 0 0 0 6.5vw;
					li{
						display: flex;
						align-self: center;
						border-top: 0.25vw solid transparent;
						& + li{
							padding: 0 0 0 1.6875vw;
						}
						a{
							color: #1f1d1f;
							font-size: 1.375vw;
							transition-property: color;
							transition-duration: 0.4s;
							letter-spacing: 0.1em;
							&:hover{
								color: $red;
							}
						}
					}
				}
			}
		}
	}
	&.isSpecialPage{
		background: #fff;

		nav{
			right: 5vw;
			height: 100%;
			height: 100%;
			ul{
				display: flex;
				justify-content: space-between;
				height: 100%;
				float: left;
				&#headMenu{
					width: 49.5vw;
					right: 9.5vw;
					transition-property: width;
					transition-duration: 0.4s;
					li{
						position: relative;
						display: flex;
						height: 100%;
						border-top: 0.25vw solid transparent;
						& + li{
							
						}
						a{
							display: block;
							left: 0;
							width: 100%;
							color: #3c3c3b;
							font-size: 1vw;
							font-family: $mont;
							font-weight: 300;
							transition-property: color;
							transition-duration: 0.4s;
							letter-spacing: 0.1em;
							text-align: center;
							align-self: center;
							transition-property: font-size;
							transition-duration: 0.4s;
							&:hover{
								color: $red;
								font-weight: 300;
							}
						}

						&.current-menu-item,
						&.current-menu-parent{
							border-top-color: $red;
							a{
								color: $red;
								font-size: 1vw;
								font-weight: 700;
								transition-property: font-size;
								transition-duration: 0.4s;
							}
							ul{
								background: $red;
								position: absolute;
								display: none !important;
								justify-content: initial;
								height: auto;
								float: initial;
								left: -3.4375vw;
								min-width: 22.0625vw;
								top: 100%;
								padding: 1.25vw 0;
								&:after{
									background: #c2010c;
									position: absolute;
									display: block;
									content: "";
									top: 50%;
									left: 50%;
									width: 2px;
									height: calc(100% - 40px);
									transform: translate(-50%, -50%);
								}
								&:before{
									background: $red;
									position: absolute;
									display: block;
									content: "";
									width: 30px;
									height: 30px;
									transform: rotate(45deg);
									top: -1vw;
									left: 5vw;
									z-index: -1;
								}
								li{
									display: block;
									width: 50%;
									a{
										color: #fff;
										text-align: left;
										font-size: 0.625vw;
										font-family: $mont;
										font-weight: 300;
										letter-spacing: 0.2em;
										text-transform: uppercase;
										transition-property: background;
										transition-duration: 0.4s;
										padding: 0.9375vw 0 0.9375vw 2.5vw;
										&:hover{
											background: #c2010c;
											font-weight: 300;
											color: #fff;
										}
									}
									&:after{
										background-image: url(../images/arrow-right.svg);
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-position: center center;
										position: absolute;
										display: block;
										content: "";
										width: 0.4375vw;
										height: 0.4375vw;
										top: 50%;
										right: 1.125vw;
										transform: translate(0, -50%);
									}
								}
							}
						}
						ul{
							background: $red;
							position: absolute;
							display: none !important;
							justify-content: initial;
							height: auto;
							float: initial;
							left: -3.4375vw;
							min-width: 22.0625vw;
							top: 100%;
							padding: 1.25vw 0;
							&:after{
								background: #c2010c;
								position: absolute;
								display: block;
								content: "";
								top: 50%;
								left: 50%;
								width: 2px;
								height: calc(100% - 2.5vw);
								transform: translate(-50%, -50%);
							}
							&:before{
								background: $red;
								position: absolute;
								display: block;
								content: "";
								width: 3.125vw;
								height: 3.125vw;
								transform: rotate(45deg);
								top: -1.25vw;
								left: 3.75vw;
								z-index: -1;
							}
							li{
								display: block;
								width: 50%;
								a{
									text-align: left;
									font-size: 0.625vw;
									font-family: $mont;
									font-weight: 300;
									letter-spacing: 0.2em;
									text-transform: uppercase;
									transition-property: background;
									transition-duration: 0.4s;
									padding: 0.9375vw 0 0.9375vw 2.5vw;
									&:hover{
										background: #c2010c;
										font-weight: 300;
										color: #fff;
									}
								}
								&:after{
									background-image: url(../images/arrow-right.svg);
									background-size: 100% 100%;
									background-repeat: no-repeat;
									background-position: center center;
									position: absolute;
									display: block;
									content: "";
									width: 0.4375vw;
									height: 0.4375vw;
									top: 50%;
									right: 1.125vw;
									transform: translate(0, -50%);
								}

								&.current-menu-item{
									a{
										background: #c2010c;
										color: #fff;
										text-align: left;
										font-size: 0.625vw;
										font-family: $mont;
										font-weight: 300;
										letter-spacing: 0.2em;
										text-transform: uppercase;
										transition-property: background;
										transition-duration: 0.4s;
										padding: 0.9375vw 0 0.9375vw 2.5vw;
										&:hover{
											background: #c2010c;
											font-weight: 300;
											color: #fff;
										}
									}
									&:after{
										background-image: url(../images/arrow-right.svg);
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-position: center center;
										position: absolute;
										display: block;
										content: "";
										width: 0.4375vw;
										height: 0.4375vw;
										top: 50%;
										right: 1.125vw;
										transform: translate(0, -50%);
									}
								}
							}
						}
						&:hover{
							ul{
								display: flex !important;
								flex-wrap: wrap;

								li{

									a{

									}
								}
							}
						}
					}
				}
				&#social{
					right: 0;
					margin: 0 0 0 6.5vw;
					li{
						display: flex;
						align-self: center;
						border-top: 0.25vw solid transparent;
						& + li{
							padding: 0 0 0 15px;
						}
						a{
							color: #d7d6d7;
							font-size: 14px;
							transition-property: color;
							transition-duration: 0.4s;
							letter-spacing: 0.1em;
							&:hover{
								color: $red;
							}
						}
					}
				}
			}
		}
	}
	&.isServ{
		height: 8.375vw !important;
		background: #3c3c3b;
		position: fixed;
		display: block;
		width: 100%;
		height: 8.375vw;
		top: 0;
		left: 0;
		z-index: 10;
		transition-property: height;
		transition-duration: 0.4s;
		#logo,
		nav{
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
		}
		#logo{
			left: 14.125vw;
		}
		nav{
			right: 5vw;
			height: 100%;
			height: 100%;
			ul{
				display: flex;
				justify-content: space-between;
				height: 100%;
				float: left;
				&#headMenu{
					width: 49.5vw;
					right: 9.5vw;
					transition-property: width;
					transition-duration: 0.4s;
					li{
						position: relative;
						display: flex;
						height: 100%;
						border-top: 0.25vw solid transparent;
						& + li{
							
						}
						a{
							display: block;
							left: 0;
							width: 100%;
							color: #fff;
							font-size: 1vw;
							font-family: $mont;
							font-weight: 300;
							transition-property: color;
							transition-duration: 0.4s;
							letter-spacing: 0.1em;
							text-align: center;
							align-self: center;
							transition-property: font-size;
							transition-duration: 0.4s;
							&:hover{
								color: $red;
								font-weight: 300;
							}
						}

						&.current-menu-item,
						&.current-menu-parent{
							border-top-color: $red;
							a{
								color: $red;
								font-size: 1vw;
								font-weight: 700;
								transition-property: font-size;
								transition-duration: 0.4s;
							}
							ul{
								background: $red;
								position: absolute;
								display: none !important;
								justify-content: initial;
								height: auto;
								float: initial;
								left: -3.4375vw;
								min-width: 22.0625vw;
								top: 100%;
								padding: 1.25vw 0;
								&:after{
									background: #c2010c;
									position: absolute;
									display: block;
									content: "";
									top: 50%;
									left: 50%;
									width: 2px;
									height: calc(100% - 40px);
									transform: translate(-50%, -50%);
								}
								&:before{
									background: $red;
									position: absolute;
									display: block;
									content: "";
									width: 30px;
									height: 30px;
									transform: rotate(45deg);
									top: -1vw;
									left: 5vw;
									z-index: -1;
								}
								li{
									display: block;
									width: 50%;
									a{
										color: #fff;
										text-align: left;
										font-size: 0.625vw;
										font-family: $mont;
										font-weight: 300;
										letter-spacing: 0.2em;
										text-transform: uppercase;
										transition-property: background;
										transition-duration: 0.4s;
										padding: 0.9375vw 0 0.9375vw 2.5vw;
										&:hover{
											background: #c2010c;
											font-weight: 300;
											color: #fff;
										}
									}
									&:after{
										background-image: url(../images/arrow-right.svg);
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-position: center center;
										position: absolute;
										display: block;
										content: "";
										width: 0.4375vw;
										height: 0.4375vw;
										top: 50%;
										right: 1.125vw;
										transform: translate(0, -50%);
									}
								}
							}
						}
						ul{
							background: $red;
							position: absolute;
							display: none !important;
							justify-content: initial;
							height: auto;
							float: initial;
							left: -3.4375vw;
							min-width: 22.0625vw;
							top: 100%;
							padding: 1.25vw 0;
							&:after{
								background: #c2010c;
								position: absolute;
								display: block;
								content: "";
								top: 50%;
								left: 50%;
								width: 2px;
								height: calc(100% - 2.5vw);
								transform: translate(-50%, -50%);
							}
							&:before{
								background: $red;
								position: absolute;
								display: block;
								content: "";
								width: 3.125vw;
								height: 3.125vw;
								transform: rotate(45deg);
								top: -1.25vw;
								left: 3.75vw;
								z-index: -1;
							}
							li{
								display: block;
								width: 50%;
								a{
									text-align: left;
									font-size: 0.625vw;
									font-family: $mont;
									font-weight: 300;
									letter-spacing: 0.2em;
									text-transform: uppercase;
									transition-property: background;
									transition-duration: 0.4s;
									padding: 0.9375vw 0 0.9375vw 2.5vw;
									&:hover{
										background: #c2010c;
										font-weight: 300;
										color: #fff;
									}
								}
								&:after{
									background-image: url(../images/arrow-right.svg);
									background-size: 100% 100%;
									background-repeat: no-repeat;
									background-position: center center;
									position: absolute;
									display: block;
									content: "";
									width: 0.4375vw;
									height: 0.4375vw;
									top: 50%;
									right: 1.125vw;
									transform: translate(0, -50%);
								}

								&.current-menu-item{
									a{
										background: #c2010c;
										color: #fff;
										text-align: left;
										font-size: 0.625vw;
										font-family: $mont;
										font-weight: 300;
										letter-spacing: 0.2em;
										text-transform: uppercase;
										transition-property: background;
										transition-duration: 0.4s;
										padding: 0.9375vw 0 0.9375vw 2.5vw;
										&:hover{
											background: #c2010c;
											font-weight: 300;
											color: #fff;
										}
									}
									&:after{
										background-image: url(../images/arrow-right.svg);
										background-size: 100% 100%;
										background-repeat: no-repeat;
										background-position: center center;
										position: absolute;
										display: block;
										content: "";
										width: 0.4375vw;
										height: 0.4375vw;
										top: 50%;
										right: 1.125vw;
										transform: translate(0, -50%);
									}
								}
							}
						}
						&:hover{
							ul{
								display: flex !important;
								flex-wrap: wrap;

								li{

									a{

									}
								}
							}
						}
					}
				}
				&#social{
					right: 0;
					margin: 0 0 0 6.5vw;
					li{
						display: flex;
						align-self: center;
						border-top: 0.25vw solid transparent;
						& + li{
							padding: 0 0 0 1.6875vw;
						}
						a{
							color: #1f1d1f;
							font-size: 1.375vw;
							transition-property: color;
							transition-duration: 0.4s;
							letter-spacing: 0.1em;
							&:hover{
								color: $red;
							}
						}
					}
				}
			}
		}
	}
}
.submenu {
	display: none;
}
.submenu-item {
	a {
		color: #FFF !important;
	}
}
.submenu-item-link {
	padding-right: 30px;
}

.toggle-menu {
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  width: 120px;
  color: #CCC;
  padding: 2px 10px 0;
  margin: 0;
  font-size: 14px;
  float: left;
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: #CCC;
  }
  .burger {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    margin-right: 10px;
    &:before,
    &:after {
      content: '';
      transition: all 0.25s $easeinoutquad;
      border-radius: 2px;
      background: #CCC;
      width: 14px;
      height: 2px;
      left: 0;
    }
    &:before {
      box-shadow: 0 4px 0 0 #CCC;
      position: absolute;
      top: 2px;
    }
    &:after {
      position: absolute;
      top: 10px;
    }
  }
  &.is--active {
    .burger {
      &:before {
        box-shadow: 0 0 0 0 transparent !important;
        transform: rotate(225deg);
        top: 6px;
      }
      &:after {
        transform: rotate(315deg);
        top: 6px;
      }
    }
  }
}
header nav ul#headMenu li ul li a,
header nav ul#headMenu li ul li.current-menu-item a,
header.active nav ul#headMenu li ul li a,
header.active nav ul#headMenu li.current-menu-item ul li a,
header.active nav ul#headMenu li.current-menu-parent ul li a,
header.isServ nav ul#headMenu li ul li a,
header.isSpecialPage nav ul#headMenu li ul li a {
	padding: 12px 26px !important;
}
